import styled from 'styled-components';
import { PxToRem } from 'common/functions/PxToRem';

export const Circle = styled.svg`
    display: flex;
    align-items: center;
    justify-items: center;
    width: ${PxToRem(60)};
    height: ${PxToRem(60)};

    &:hover {
        & circle.withAnim {
            stroke-dashoffset: 0;
        }
    }
`;
