import styled, { keyframes } from 'styled-components';
import { up } from 'styled-breakpoints';

const Footer_Animation = (colors: string[]) => keyframes`
    0% {
        color: ${colors[1]};
    }
    45% {
      color: ${colors[1]};
    }
    50% {
      color: ${colors[0]};
    }
    95% {
        color: ${colors[0]};
    }
    100% {
        color: ${colors[1]};
    }
`;

export const Footer__BackgroundFont = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1.5) rotate(90deg);
    opacity: 0.56;

    ${up('md')} {
        top: -19%;
        bottom: initial;
        transform: translateX(-50%) scale(1.35);
        opacity: 1;
    }

    ${up('lg')} {
        transform: translateX(-50%) scale(1.5);
    }

    ${up('xl')} {
        transform: translateX(-50%) scale(1.5);
    }

    & h4 {
        color: transparent;
    }
`;
