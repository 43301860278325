import React from 'react';
import { Link } from 'gatsby';
import { BreakpointConverter } from 'common/functions/BreakpointConverter';
import { Grid, Grid__Item } from 'components/Grid';
import { Text } from 'atoms/Text';
import { TextFactory, I_TextBlock } from 'components/Sections/TextFactory';

export interface ProjectTeaserProps {
    item: any;
}

export const ProjectTeaser: React.FC<ProjectTeaserProps> = ({ item }) => {
    const { headline, backgroundColor, link } = item;

    return (
        <div style={{ backgroundColor: backgroundColor, position: 'relative' }}>
            <Grid
                modifiers={item.gridWrapper.modifiers}
                spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
                spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
            >
                <Grid__Item responsive={{ sm: { span: 12 }, md: { start: 3, span: 8 } }}>
                    <Text modifiers={['colorGreyLight', 'colorWhite', 'center', 'spacingBottom']} spacingBottom={'xxl'}>
                        Next project
                    </Text>
                    {headline.map((item: I_TextBlock, index: number) => {
                        return <React.Fragment key={index}>{TextFactory(item)}</React.Fragment>;
                    })}
                </Grid__Item>
            </Grid>
            <Link to={link} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }} />
        </div>
    );
};
