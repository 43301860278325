import styled from 'styled-components';

import { Modifiers } from 'common/functions/Modifiers';
import { TextModifiers, T_Spacing } from './css_TextModifiers';

export type T_TextModifiers = keyof typeof TextModifiers;
export type T_TextType = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'small' | 'span';

export interface I_Text {
    type?: T_TextType;
    modifiers?: T_TextModifiers[];
    spacingTop?: T_Spacing;
    spacingBottom?: T_Spacing;
    spacingLeft?: T_Spacing;
    spacingRight?: T_Spacing;
}

export const Text = styled.div<I_Text>`
    ${props => {
        return Modifiers(TextModifiers, props.modifiers);
    }}
`;
