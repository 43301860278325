import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { motion as Motion } from 'framer-motion';

import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';

type T_Tile__Graphic = keyof typeof Modifiers_Tile__Graphic;

interface I_Tile__Graphic {
    modifiers?: T_Tile__Graphic[];
}

const Modifiers_Tile__Graphic = {
    child: () => css`
        z-index: 2;
        background: ${props => props.theme.colors.highlight.IV};
        mix-blend-mode: multiply;
    `,
    childII: () => css`
        z-index: 2;
        background: ${props => props.theme.colors.highlight.II};
        mix-blend-mode: multiply;
    `,
    childIII: () => css`
        z-index: 2;
        background: ${props => props.theme.colors.highlight.III};
        mix-blend-mode: multiply;
    `,
    childIV: () => css`
        z-index: 2;
        background: ${props => props.theme.colors.highlight.I};
        mix-blend-mode: multiply;
    `,
    smaller: () => css`
        transform: scale(0);
    `,
    border: () => css`
        border: ${PxToRem(1.9)} solid ${props => props.theme.colors.foreground.I};
    `,
    transparent: () => css`
        background: transparent;
    `
};

export const Tile__Graphic = styled(Motion.div)<I_Tile__Graphic>`
    position: absolute;
    z-index: 3;
    width: ${PxToRem(120)};
    height: ${PxToRem(120)};
    border-radius: 50%;
    background: ${props => props.theme.colors.background.I};

    will-change: left, top;

    ${up('md')} {
        width: ${PxToRem(150)};
        height: ${PxToRem(150)};
    }

    ${up('lg')} {
        width: ${PxToRem(220)};
        height: ${PxToRem(220)};
    }

    ${up('xxxl')} {
        width: ${PxToRem(280)};
        height: ${PxToRem(280)};
    }

    ${props => Modifiers(Modifiers_Tile__Graphic, props.modifiers)}
`;
