import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

export interface SeoProps {
    seoProps: {
        title: string;
        siteUrl: string;
        canonical: string;
        description: string;
        keywords: string;
        imageSocialShare: string;
    };
}

export const Seo: React.FC<SeoProps> = ({ seoProps }) => {
    const { title, siteUrl, canonical, description, keywords, imageSocialShare } = seoProps;
    const {
        site: { siteMetadata }
    } = useStaticQuery(graphql`
        query SeoQuery {
            site {
                siteMetadata {
                    title
                    siteUrl
                    keywords
                }
            }
        }
    `);
    return (
        <Helmet htmlAttributes={{ lang: 'en' }}>
            <title>
                {title} \ {siteMetadata.title}
            </title>
            <link rel="canonical" href={canonical} />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={siteUrl} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageSocialShare} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content={imageSocialShare} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageSocialShare} />
        </Helmet>
    );
};
