import styled, { keyframes } from 'styled-components';
import { up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

const AnimBlinking = keyframes`
    0% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
`;

export const Stage__Caret = styled.div`
    position: relative;
    margin: ${Gaps.xl} auto;
    width: ${PxToRem(40)};
    min-height: ${PxToRem(240)};
    height: 70%;
    background: #2697ed;
    border-radius: ${PxToRem(4)};

    animation: ${AnimBlinking} 1.5s cubic-bezier(1, 0, 0, 1) infinite;

    ${up('sm')} {
        margin: ${Gaps.xxxl} auto;
    }

    ${up('md')} {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: initial;
        width: ${PxToRem(60)};
        border-radius: ${PxToRem(8)};
        transform: translate(-50%, -50%);
    }
`;
