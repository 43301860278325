import styled, { css } from 'styled-components';
import { PxToRem } from 'common/functions/PxToRem';
import { Modifiers } from 'common/functions/Modifiers';

const CIRCLE_RADIUS = 25;

const getDashvalue = (radius: number) => {
    const circumFerence = 2 * 3.1415927 * radius;
    return circumFerence;
};

type T_ModifiersCircleCircOutline = keyof typeof ModifiersPaneSVGCircle;

interface I_CircleCircOutline {
    crossIsDark: boolean;
    modifiers?: T_ModifiersCircleCircOutline[];
}

const ModifiersPaneSVGCircle = {
    withAnim: () => css`
        stroke-width: ${PxToRem(2)};
        stroke-dasharray: ${getDashvalue(CIRCLE_RADIUS)};
        stroke-dashoffset: ${getDashvalue(CIRCLE_RADIUS)};
        transition: stroke-dashoffset 0.35s ease;
        transform-origin: center center;
    `
};

export const Circle__CircOutline = styled.circle<I_CircleCircOutline>`
    fill: none;
    stroke: ${props => (props.crossIsDark ? props.theme.colors.foreground.I : props.theme.colors.background.I)};
    stroke-width: ${PxToRem(0.5)};

    ${props => Modifiers(ModifiersPaneSVGCircle, props.modifiers)}
`;
