import { FlattenInterpolation, ThemeProps } from 'styled-components';

export function Modifiers<T extends ThemeProps<any>>(
    options: {
        [k: string]: (props?: any) => FlattenInterpolation<T>;
    },
    modifiers: string[] | undefined | null
) {
    if (modifiers !== undefined && modifiers !== null) {
        return modifiers.map(mod => {
            return options[mod];
        });
    }
}
