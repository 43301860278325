import React from 'react';
import _ from 'lodash';
import { Text } from 'atoms/Text';
import { I_TextBlock } from 'components/Sections/TextFactory';
import { LogosSvg } from './logosSvg';

import * as LL from './css';

export interface LogosProps {
    headline: I_TextBlock[];
}

export const Logos: React.FC<LogosProps> = ({ headline }) => {
    return (
        <LL.Logos>
            {_.map(headline, (item, index) => (
                <Text key={index} type={item.type} modifiers={item.modifiers}>
                    {item.content}
                </Text>
            ))}
            <LL.Logos__Line>
                <LogosSvg type={'miele'} />
                <LogosSvg type={'audi'} />
                <LogosSvg type={'fleetboard'} />
                <LogosSvg type={'bauwatch'} />
                <LogosSvg type={'smartReporting'} />
                <LogosSvg type={'therabody'} />
            </LL.Logos__Line>
        </LL.Logos>
    );
};
