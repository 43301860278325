import styled, { keyframes, css } from 'styled-components';
import { Modifiers } from 'common/functions/Modifiers';

export type T_LogoBar = keyof typeof ModifiersLogoBar;

export interface I_LogoBar {
    modifiers?: T_LogoBar[];
}

const ModifiersLogoBar = {
    animationDelay: () => css`
        animation-delay: 0.65s;
    `
};

const AnimationLogoBar = keyframes`
    from {
        ${css`
            transform: translateX(-100%);
        `}
    }

    to {
        ${css`
            transform: translateX(0);
        `}
    }
`;

export const Logo__Bar = styled.rect<I_LogoBar>`
    fill: ${props => props.theme.colors.foreground.I};
    animation: ${AnimationLogoBar} 0.5s 0.35s ease both;

    ${props => Modifiers(ModifiersLogoBar, props.modifiers)}
`;
