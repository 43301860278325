import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

export const ImageText__Image = styled.div`
    display: flex;
    margin-left: -${Gaps.xl};
    margin-right: -${Gaps.xl};
    min-height: ${PxToRem(320)};
    background-color: ${props => props.theme.colors.highlight.IV};
    background-size: cover;
    background-position: center right;

    ${up('md')} {
        margin-left: -${Gaps.xl};
        margin-right: initial;
        min-height: ${PxToRem(560)};
    }

    ${up('xl')} {
        margin-left: -${Gaps.xxl};
        min-height: ${PxToRem(800)};
    }

    & div {
        flex: 1;
        height: inherit;
    }
`;
