import React from 'react';

import { Text } from 'atoms/Text';
import * as DL from './css';

export interface DefListProps {
    points: {
        content: string;
    }[];
}

export const DefList: React.FC<DefListProps> = ({ points }) => {
    return (
        <>
            {points.map((point: { content: string }, index: number) => {
                return (
                    <DL.DefList key={index}>
                        <DL.DefList__Index>
                            <Text type={'h3'} modifiers={['advert', 'colorHighlightI', 'weight300', 'mono']}>
                                <span style={{ fontVariantNumeric: 'slashed-zero' }}>0</span>
                                {index + 1}
                            </Text>
                        </DL.DefList__Index>
                        <DL.DefList__Content>
                            <Text type={'p'} modifiers={['paragraph', 'weight500', 'lineHeightIncreased']}>
                                {point.content}
                            </Text>
                        </DL.DefList__Content>
                    </DL.DefList>
                );
            })}
        </>
    );
};
