import styled from 'styled-components';
import { motion as Motion } from 'framer-motion';
import { PxToRem } from 'common/functions/PxToRem';

interface I_Pane {}

export const Pane = styled(Motion.div)<I_Pane>`
    position: relative;
    height: inherit;
    border-radius: ${PxToRem(24)};
`;
