import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Gaps } from 'common/settings/Gaps';

export const Logos__Line = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: ${Gaps.l} 0 0;
    padding: 0;
    list-style: none;

    ${up('md')} {
        margin-top: ${Gaps.xxl};
    }
`;
