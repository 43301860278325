import React from 'react';

import { BreakpointColumnConverter, BreakpointConverter } from 'common/functions/BreakpointConverter';
import { Grid, Grid__Item } from 'components/Grid';
import { CvPoint } from './CvPoint';

import * as CV from './css';

export interface CvProps {
    item: any;
}

export const Cv: React.FC<CvProps> = ({ item }) => {
    const { gridWrapper, gridWrapperItems, cvPoints } = item;
    return (
        <CV.Cv>
            {/* <CV.Cv__Bar /> */}
            <Grid
                modifiers={gridWrapper.modifiers}
                spacingTop={BreakpointConverter(gridWrapper.spacingTop)}
                spacingBottom={BreakpointConverter(gridWrapper.spacingBottom)}
            >
                <Grid__Item
                    modifiers={gridWrapperItems.modifiers}
                    responsive={BreakpointColumnConverter(gridWrapperItems.spacingTop)}
                >
                    <Grid modifiers={['noPadding']}>
                        {cvPoints.map((cvPoint: any, index: number) => (
                            <CvPoint key={index} {...cvPoint} />
                        ))}
                    </Grid>
                </Grid__Item>
            </Grid>
        </CV.Cv>
    );
};
