import styled, { css } from 'styled-components';
import { motion as Motion } from 'framer-motion';
import { lighten } from 'polished';
import { up } from 'styled-breakpoints';

import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

type T_Tile = keyof typeof Modifiers_Tile;

export interface I_Tile {
    backgroundColor?: string;
    modifiers?: T_Tile[] | any;
}

const Modifiers_Tile = {
    small: () => css`
        max-height: ${PxToRem(180)};

        ${up('xl')} {
            max-height: ${PxToRem(250)};
        }
    `,
    research: (props: any) => css`
        background-color: ${props => props.theme.colors.highlight.III};
    `,
    strategy: (props: any) =>
        css`
            background-color: ${props => props.theme.colors.highlight.II};
        `,
    design: (props: any) =>
        css`
            background-color: ${props => props.theme.colors.highlight.I};
        `,
    development: (props: any) =>
        css`
            background-color: ${props => props.theme.colors.highlight.IV};
        `
};

export const Tile = styled(Motion.article)<I_Tile>`
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: ${Gaps.m} ${Gaps.xl};
    height: 100vw;
    max-height: ${PxToRem(240)};
    border-radius: ${PxToRem(6)};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.colors.background.II)};
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
    }

    ${up('md')} {
        max-height: ${PxToRem(300)};
    }

    ${up('xl')} {
        max-height: ${PxToRem(350)};
    }

    ${props => Modifiers(Modifiers_Tile, props.modifiers)}
`;
