import React from 'react';

import { I_BandAid__Path } from './css/css_BandAid__Path';
import * as BA from './css';

export interface BandAidProps extends I_BandAid__Path {}

export const BandAid: React.FC<BandAidProps> = ({ modifiers }) => {
    return (
        <BA.BandAid width="342px" height="321px" viewBox="0 0 342 321" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <BA.BandAid__Path
                modifiers={modifiers}
                d="M341.414045,295.789419 C328.230225,303.855861 315.046405,311.922303 301.06225,320.478322 C281.351696,296.915377 261.513594,272.910618 241.346106,249.187524 C227.346532,232.720294 212.669266,216.830443 198.760098,200.28946 C193.710692,194.284583 189.943786,194.580998 183.799,198.80106 C149.605307,222.286038 115.107459,245.329202 80.245184,268.838764 C62.6377906,250.286791 44.5131922,231.189047 24.8439873,210.46523 C60.9088684,187.005539 98.4770078,162.568097 138.73209,136.382364 C92.4373439,96.8249088 46.7432004,57.7802107 0.248020082,18.0513683 C13.8677496,12.1307796 27.3956717,5.56748862 41.4793439,0.576184464 C45.3058193,-0.780163457 51.9594012,1.20905317 55.3667865,4.02570525 C96.0493684,37.6443092 136.511192,71.535447 176.609291,105.849434 C182.468143,110.863215 185.977848,112.218158 193.815114,109.023611 C217.282061,99.4568278 241.447024,91.5835477 265.476028,83.4496746 C268.251975,82.509854 273.096741,82.8715937 274.922377,84.696447 C289.875766,99.6380488 304.339983,115.070633 319.452459,130.876898 C291.651643,143.029944 265.307131,154.546609 236.998221,166.922318 C245.867795,177.032764 253.337832,185.094992 260.305381,193.572342 C284.428995,222.925931 308.348672,252.448097 332.436545,281.831889 C335.134,285.122666 338.407528,287.94002 341.414045,290.977228 L341.414045,295.789419 Z"
                id="Fill-1"
            ></BA.BandAid__Path>
        </BA.BandAid>
    );
};
