import React from 'react';

import * as LI from './css';
import { I_Link } from './css/css_Link';

export interface LinkProps extends I_Link {
    hRef: string;
    title: string;
}

export const Link: React.FC<LinkProps> = ({ hRef, title, modifiers }) => {
    return (
        <LI.Link href={hRef} title={title} modifiers={modifiers}>
            {title}
        </LI.Link>
    );
};
