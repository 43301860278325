import React from 'react';

import { BreakpointConverter } from 'common/functions/BreakpointConverter';
import { Grid, Grid__Item } from 'components/Grid';
import { Image } from 'components/Image';

import * as FI from 'components/FullImage/css';

export interface ImageCoupleProps {
    item: any;
}

export const ImageCouple: React.FC<ImageCoupleProps> = ({ item }) => {
    const { images, backgroundColors, modifiers } = item;

    return (
        <Grid
            modifiers={item.gridWrapper.modifiers}
            spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
            spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
        >
            {images.map((image: string, index: number) => (
                <Grid__Item
                    key={index}
                    responsive={
                        index === 0
                            ? { sm: { span: 6 }, lg: { start: 2, span: 5 } }
                            : { sm: { span: 6 }, lg: { span: 5 } }
                    }
                >
                    <FI.FullImage backgroundColor={backgroundColors[index]} modifiers={modifiers[index]}>
                        <Image src={image} />
                    </FI.FullImage>
                </Grid__Item>
            ))}
        </Grid>
    );
};
