import React, { useContext, useEffect, useState } from 'react';
import { MotionValue, useTransform, motion as Motion } from 'framer-motion';

import { ThemeContext } from '../../context/ThemeState';
import { OverlayContext } from '../../context/Overlay';

import { Text } from 'atoms/Text';
import { Grid, Grid__Item } from 'components/Grid';
import { Seo } from 'components/Seo';

import * as MO from './css';
import * as ST from 'common/styles/Stage';
import * as BI from 'common/styles/BackgroundSection';

export interface ModalProps {
    top: number;
    left: number;
    width: number;
    height: number;
    isShown: boolean;
    content: { title: string; headline: string; img: string };
    y: MotionValue<number>;
    closeModal: () => void;
}

export const Modal: React.SFC<ModalProps> = ({ top, left, width, height, isShown, content, y, closeModal }) => {
    let timeout: any = null;
    const { changeTheme } = useContext(ThemeContext);
    const { setModalVisibility } = useContext(OverlayContext);
    const [pageContent, setPageContent] = useState<any>();
    let value;

    value = useTransform(y, value => 1 - value);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('../../content/projects/smart-reporting-branding.json');
            const data = await response.json();
            setPageContent(data);
        };

        fetchData();
    }, [setPageContent]);

    return (
        <MO.Modal
            onClick={() => {
                if (timeout !== null) {
                    clearTimeout(timeout);
                }

                timeout = setTimeout(async () => {
                    closeModal();
                    //changeTheme();
                }, 350);
            }}
            top={top}
            left={left}
            width={width}
            height={height}
            isShown={isShown}
            style={{ y: y }}
            transition={{ type: 'spring', stiffness: 200, damping: 30 }}
        >
            <Seo title={'trial'} />
            {pageContent && (
                <article style={{ overflowY: 'scroll' }}>
                    <ST.Stage backgroundImage={content.img}>
                        <ST.Stage__Header>
                            <Grid>
                                <Grid__Item
                                    responsive={{
                                        md: { start: 2, span: 10 },
                                        xxxl: { start: 4, span: 6 }
                                    }}
                                >
                                    <Motion.div
                                        animate={{ y: [50, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.45, delay: 0.75, ease: 'easeInOut' }}
                                    >
                                        <Text
                                            type={'h4'}
                                            modifiers={[
                                                'paragraph',
                                                'weight500',
                                                'colorWhite',
                                                'spacingBottom',
                                                'mono'
                                            ]}
                                            spacingBottom={'xl'}
                                        >
                                            {pageContent.brand}
                                            <Text type="span" modifiers={['colorGreyMedium']}>
                                                &nbsp;×&nbsp;
                                            </Text>
                                            {pageContent.location}
                                        </Text>
                                    </Motion.div>
                                    <Motion.div
                                        animate={{ y: [50, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.45, delay: 0.65, ease: 'easeInOut' }}
                                    >
                                        <Text
                                            type={'h2'}
                                            modifiers={[
                                                'headline',
                                                'colorWhite',
                                                'lineHeightHeadline',
                                                'weight500',
                                                'spacingBottom'
                                            ]}
                                            spacingBottom={'xxl'}
                                        >
                                            {pageContent && pageContent.headline}
                                        </Text>
                                    </Motion.div>
                                    <div style={{ opacity: 0.8 }}>
                                        {pageContent.tasks.map((task: string, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Motion.span
                                                        animate={{ y: [100, 0], opacity: [0, 1] }}
                                                        transition={{
                                                            duration: 0.45,
                                                            delay: 0.95 * (index + 1),
                                                            ease: 'easeInOut'
                                                        }}
                                                    >
                                                        <Text
                                                            key={index}
                                                            type={'span'}
                                                            modifiers={['small', 'mono', 'colorWhite', 'weight400']}
                                                        >
                                                            {task}
                                                        </Text>
                                                    </Motion.span>
                                                    {index < pageContent.tasks.length - 1 && (
                                                        <Motion.span
                                                            animate={{ y: [100, 0], opacity: [0, 1] }}
                                                            transition={{
                                                                duration: 0.45,
                                                                delay: 1 * (index + 1),
                                                                ease: 'easeInOut'
                                                            }}
                                                        >
                                                            <Text
                                                                type={'span'}
                                                                modifiers={[
                                                                    'colorGreen',
                                                                    'spacingLeft',
                                                                    'spacingRight'
                                                                ]}
                                                                spacingLeft={'s'}
                                                                spacingRight={'s'}
                                                            >
                                                                ×
                                                            </Text>
                                                        </Motion.span>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </Grid__Item>
                            </Grid>
                        </ST.Stage__Header>
                        <ST.Stage__Cover />
                    </ST.Stage>
                    <Grid
                        modifiers={['spacingTop', 'spacingBottom']}
                        spacingTop={[{ default: 'xl' }, { md: 'xxl' }]}
                        spacingBottom={[{ default: 'xl' }, { md: 'xl' }]}
                    >
                        <Grid__Item responsive={{ lg: { start: 6, span: 6 } }}>
                            <Text type="h3" modifiers={['legible', 'tertiary', 'spacingBottom']} spacingBottom={'xl'}>
                                Headline
                            </Text>
                            <Text type="p" modifiers={['legible', 'spacingBottom']} spacingBottom={'xl'}>
                                In-Vision is at the forefront of tech, specializing in light engines for 3D printers and
                                lenses. 55% of all movie theatre projectors worldwide use invision technology. This
                                world-leading manufacturer contacted wild to help create a new company website.
                                products.
                            </Text>
                            <Text type="p" modifiers={['legible']}>
                                They wanted to be sure to use a fresh set of branding materials to get across
                                In-Vision’s company persona, as well as some bespoke CGI visuals to show off their
                                flagship products.
                            </Text>
                        </Grid__Item>
                    </Grid>
                    <Grid
                        modifiers={['spacingTop', 'spacingBottom']}
                        spacingTop={[{ default: 'm' }]}
                        spacingBottom={[{ default: 'xl' }, { md: 'xxl' }]}
                    >
                        <Grid__Item>
                            <BI.BackgroundSection backgroundImage={content.img}>
                                <Text
                                    type="h3"
                                    modifiers={[
                                        'paragraph',
                                        'uppercase',
                                        'weight800',
                                        'center',
                                        'spacingTop',
                                        'spacingBottom'
                                    ]}
                                    spacingTop={'xxxl'}
                                    spacingBottom={'xxl'}
                                >
                                    What about
                                </Text>
                                <Text
                                    type="h3"
                                    modifiers={['advert', 'center', 'spacingBottom']}
                                    spacingBottom={'xxxl'}
                                >
                                    The visual design of other health startups?
                                </Text>
                            </BI.BackgroundSection>
                        </Grid__Item>
                    </Grid>
                    {pageContent && pageContent.headline}
                </article>
            )}
        </MO.Modal>
    );
};
