import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Gaps } from 'common/settings/Gaps';

export const Pane__Header = styled.header`
    position: relative;
    z-index: 3;
    width: 75%;
    padding-left: ${Gaps.xxl};
    padding-right: ${Gaps.xxl};

    ${down('sm')} {
        padding-left: ${Gaps.xl};
        padding-right: ${Gaps.xl};
    }
`;
