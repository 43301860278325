import { Breakpoints } from './Breakpoints';
import { ThemeLight, ThemeDark } from 'common/settings/Colors';

export interface I_Theme {
    breakpoints: typeof Breakpoints;
    theme: any;
}

export interface ThemedComponent {
    theme?: I_Theme;
}

export const theme: Readonly<I_Theme> = {
    breakpoints: Breakpoints,
    theme: ThemeDark
};
