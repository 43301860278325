import React from 'react';

import * as TS from './css';
import { I_TextSpacer } from './css/css_TextSpacer';

export interface TextSpacerProps extends I_TextSpacer {
    children: React.ReactNode;
}

export const TextSpacer: React.FC<TextSpacerProps> = ({ children, css, modifiers }) => {
    return (
        <TS.TextSpacer style={css} modifiers={modifiers}>
            {children}
        </TS.TextSpacer>
    );
};
