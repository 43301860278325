import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

interface I_Cv__Point__Body {
    isShown: boolean;
}

interface I_Cv__Point__Text {
    isShown: boolean;
}

export const Cv__Point = styled.div`
    ${down('sm')} {
        margin-top: ${Gaps.xxl};
    }
`;

export const Cv__Point__Body = styled.div<I_Cv__Point__Body>`
    overflow: hidden;
`;

export const Cv__Point__Text = styled.div<I_Cv__Point__Text>`
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, transform 0.5s ease, opacity 0.5s ease;
    max-height: 0;
    opacity: 0;
    transform: translateY(-50%);
    ${props =>
        props.isShown &&
        css`
            transition: max-height 0.7s ease-in-out, transform 0.5s ease, opacity 0.5s ease;
            max-height: ${PxToRem(1000)};
            opacity: 1;
            transform: translateY(0%);
        `}
`;
