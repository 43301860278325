import styled, { css } from 'styled-components';

import { PxToRem } from 'common/functions/PxToRem';
import { Modifiers } from 'common/functions/Modifiers';
import { Gaps } from 'common/settings/Gaps';

type T_TileHeader = keyof typeof ModifiersTileHeader;

interface I_TileHeader {
    modifiers?: T_TileHeader[];
}

const ModifiersTileHeader = {
    noBorderTop: () => css`
        border-top: 0;
    `
};

export const Tile__Header = styled.header<I_TileHeader>`
    position: relative;
    z-index: 10;
    padding-top: ${Gaps.s};
    width: 100%;
    border-top: ${PxToRem(1)} solid ${props => props.theme.colors.foreground.II};

    ${props => Modifiers(ModifiersTileHeader, props.modifiers)};
`;
