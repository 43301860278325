import styled from 'styled-components';
import Image from 'gatsby-image';

export const Pane__Img = styled(Image)`
    position: relative;
    z-index: 2;
    flex: 0 0 70%;
    position: relative;
    width: 100%;
    object-fit: contain;
    overflow: visible !important;

    & img {
        object-fit: contain;
    }
`;

export const Pane__BgWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
