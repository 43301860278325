import styled, { keyframes } from 'styled-components';
import { PxToRem } from 'common/functions/PxToRem';

const Pane__Progress_Animation = keyframes`
      0% {
        width: 0%
      }
      100% {
        width: 100%;
      }
`;

export const Pane__Progress = styled.div`
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: ${PxToRem(4)};
    background-color: ${props => props.theme.colors.highlight.III};
    animation: ${Pane__Progress_Animation} 0.75s 0.5s ease-in-out both;
`;
