import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

export const DefList__Content = styled.dd`
    flex: 1;
    padding-bottom: ${Gaps.xxl};
    margin-left: ${Gaps.xl};
    margin-bottom: ${Gaps.xxl};

    border-bottom: ${PxToRem(1)} solid ${props => props.theme.colors.foreground.I};

    ${up('xl')} {
        margin-left: ${Gaps.xxxl};
    }
`;
