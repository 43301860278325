import styled from 'styled-components';
import { motion as Motion } from 'framer-motion';

import { Gaps } from 'common/settings/Gaps';

export const Stage__Header = styled(Motion.header)`
    position: relative;
    z-index: 2;
    width: 100%;
    padding: ${Gaps.xxxxl} 0;
`;
