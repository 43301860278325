import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';

export const Tile__Thumb = styled.img`
    width: ${PxToRem(120)};
    height: ${PxToRem(120)};
    border-radius: ${PxToRem(4)};

    ${up('sm')} {
        width: ${PxToRem(160)};
        height: ${PxToRem(160)};
    }

    ${up('md')} {
        width: ${PxToRem(200)};
        height: ${PxToRem(200)};
    }
`;
