import React from 'react';

import { BreakpointConverter } from 'common/functions/BreakpointConverter';
import { Grid, Grid__Item } from 'components/Grid';

import * as FI from 'components/FullImage/css';

export interface VideoProps {
    item: any;
}

export const Video: React.FC<VideoProps> = ({ item }) => {
    const { image, backgroundColor } = item;

    return (
        <Grid
            modifiers={item.gridWrapper.modifiers}
            spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
            spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
        >
            <Grid__Item responsive={{ sm: { span: 12 }, lg: { start: 2, span: 10 } }}>
                <FI.FullImage backgroundColor={backgroundColor}>
                    <img className={'imgGif'} src={image} />
                </FI.FullImage>
            </Grid__Item>
        </Grid>
    );
};
