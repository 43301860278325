import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';

export const BandAid = styled.svg`
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: ${PxToRem(200)};
    transform: translate(-50%, -50%);

    ${down('sm')} {
        width: ${PxToRem(140)};
    }
`;
