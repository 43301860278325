import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

export const Gallery__Item = styled.div`
    flex-basis: ${PxToRem(300)};
    padding: ${Gaps.m};
    height: 100%;

    ${up('sm')} {
        flex-basis: ${PxToRem(480)};
        padding: ${Gaps.xl};
    }

    ${up('md')} {
        flex-basis: ${PxToRem(560)};
    }

    ${up('lg')} {
        flex-basis: ${PxToRem(640)};
    }
`;
