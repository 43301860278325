import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

type T_Link = keyof typeof ModifiersLink;

export interface I_Link {
    modifiers?: T_Link[];
}

const ModifiersLink = {
    inverted: () => css`
        background-color: ${props => props.theme.colors.background.I};
        color: ${props => props.theme.colors.foreground.I};
        font-weight: 600;
        transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;

        &:hover {
            background-color: ${props => darken(0.2, props.theme.colors.background.I)};
        }

        &:active {
            transform: scale(0.95);
        }
    `
};

export const Link = styled.a<I_Link>`
    display: inline-block;
    padding: ${Gaps.m} ${Gaps.xxl};
    background-color: ${props => props.theme.colors.foreground.I};
    border-radius: ${PxToRem(24)};
    color: ${props => props.theme.colors.background.I};
    font-weight: 600;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;

    &:hover {
        background-color: ${props => lighten(0.2, props.theme.colors.foreground.I)};
    }

    &:active {
        transform: scale(0.95);
    }

    ${props => Modifiers(ModifiersLink, props.modifiers)}
`;
