import styled from 'styled-components';
import { PxToRem } from 'common/functions/PxToRem';

export const Tile__Shape = styled.div`
    position: absolute;
    right: ${PxToRem(-80)};
    bottom: ${PxToRem(-80)};
    width: ${PxToRem(300)};
    height: ${PxToRem(300)};
    background-color: ${props => props.theme.colors.highlight.III};
    mix-blend-mode: multiply;
    transform: rotate(90deg);
`;
