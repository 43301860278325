import React from 'react';

import { Grid, Grid__Item } from 'components/Grid';
import { Text } from 'atoms/Text';
import { TextSpacer } from 'atoms/TextSpacer';
import { T_TextModifiers, T_TextType } from 'atoms/Text/css/css_Text';
import { T_Spacing } from 'atoms/Text/css/css_TextModifiers';
import { TextFactory, I_TextBlock } from 'components/Sections/TextFactory';

export interface HeadlineTextProps {
    headline: I_TextBlock[];
    text: I_TextBlock[];
}

export const HeadlineText: React.FC<HeadlineTextProps> = ({ headline, text }) => {
    const renderText = (
        type: T_TextType,
        content: string,
        modifiers?: T_TextModifiers[],
        spacingTop?: T_Spacing,
        spacingBottom?: T_Spacing,
        children?: I_TextBlock[]
    ) => (
        <Text type={type} modifiers={modifiers} spacingTop={spacingTop} spacingBottom={spacingBottom}>
            {content}
            {children !== undefined && children?.length > 0 ? (
                <>
                    {children.map((child: I_TextBlock, index: number) => (
                        <React.Fragment key={index}>
                            {renderText(
                                child.type,
                                child.content,
                                child.modifiers,
                                child.spacingTop,
                                child.spacingBottom,
                                child.children
                            )}
                        </React.Fragment>
                    ))}
                </>
            ) : null}
        </Text>
    );

    return (
        <>
            <Grid modifiers={['noPadding']}>
                <Grid__Item
                    responsive={{
                        md: { start: 2, span: 8 },
                        xxxl: { start: 3, span: 8 }
                    }}
                >
                    <Text
                        type={'h2'}
                        modifiers={['headline', 'lineHeightHeadline', 'weight600', 'spacingTop']}
                        spacingTop={'l'}
                    >
                        {headline.map((item: I_TextBlock, index: number) => {
                            return <React.Fragment key={index}>{TextFactory(item)}</React.Fragment>;
                        })}
                    </Text>
                </Grid__Item>
            </Grid>
            <Grid modifiers={['noPadding']}>
                <Grid__Item
                    responsive={{
                        md: { start: 2, span: 8 },
                        xl: { start: 6, span: 6 }
                    }}
                >
                    <TextSpacer modifiers={['medium']}>
                        {text.map((item: I_TextBlock, index: number) => {
                            return <React.Fragment key={index}>{TextFactory(item)}</React.Fragment>;
                        })}
                    </TextSpacer>
                </Grid__Item>
            </Grid>
        </>
    );
};
