import React from 'react';

import * as TXT from './css';
import { I_Text } from './css/css_Text';

export interface TextProps extends I_Text {
    children: React.ReactNode;
}

export const Text: React.FC<TextProps> = ({
    children,
    modifiers,
    spacingTop,
    spacingBottom,
    spacingLeft,
    spacingRight,
    type
}) => {
    return (
        <TXT.Text
            as={type}
            modifiers={modifiers}
            spacingTop={spacingTop}
            spacingBottom={spacingBottom}
            spacingLeft={spacingLeft}
            spacingRight={spacingRight}
        >
            {children}
        </TXT.Text>
    );
};
