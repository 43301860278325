import { I_TextSpacer } from 'atoms/TextSpacer/css/css_TextSpacer';
import React from 'react';

import { BreakpointConverter, BreakpointColumnConverter } from 'common/functions/BreakpointConverter';
import { Grid, Grid__Item } from 'components/Grid';
import { TextFactory } from 'components/Sections/TextFactory';

import * as TT from './css';
import { I_TextTeaser } from './css/css_TextTeaser';

export interface TextTeaserProps extends I_TextTeaser {
    item: any;
}

export const TextTeaser: React.FC<TextTeaserProps> = ({ item, modifiers }) => {
    return (
        <TT.TextTeaser modifiers={modifiers}>
            <Grid
                modifiers={item.gridWrapper.modifiers}
                spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
                spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
            >
                {item.gridWrapperItems.map((column: any, index: number) => {
                    BreakpointColumnConverter(column.responsive);
                    return (
                        <Grid__Item key={index} responsive={BreakpointColumnConverter(column.responsive)}>
                            {item.text.map((item: any, index: number) => (
                                <TextFactory key={index} {...item} />
                            ))}
                        </Grid__Item>
                    );
                })}
            </Grid>
        </TT.TextTeaser>
    );
};
