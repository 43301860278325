import React, { useCallback, useRef, useState } from 'react';
import { useCycle, AnimatePresence, motion as Motion } from 'framer-motion';

import { Colors } from 'common/settings/Colors';
import { Text } from 'atoms/Text';

import { I_Tile } from './css/css_Tile';
import * as TI from './css';

export type T_TileType = 'research' | 'strategy' | 'design' | 'development' | 'none';

export interface TileProps extends I_Tile {
    title: string;
    shortTitle: string;
    type: T_TileType;
}

export const Tile: React.FC<TileProps> = ({ title, shortTitle, type, modifiers }) => {
    let timeout: any = null;
    const [xC, cycleX] = useCycle('50%', '5%', '10%', '75%', '32%', '25%', '0%');
    const [isInside, setInside] = useState<boolean>(false);
    const [background, cycleBackground] = useCycle(
        `${Colors.highLightGreen}`,
        `${Colors.highLightRed}`,
        `${Colors.highLightYellow}`,
        `${Colors.highLightBlue}`
    );
    const poems = [
        `"Wird's besser? Wird's schlimmer?" fragt man alljährlich. Seien wir ehrlich: Leben ist immer lebensgefährlich.`,
        `Er knackt einfach Jede Nuß Und die nicht will Muß`,
        `We all have our time machines, don't we. Those that take us back are memories...And those that carry us forward, are dreams.`
    ];
    const refTile = useRef<HTMLDivElement>(null);
    const refTileGraphic = useRef<HTMLDivElement>(null);

    const [elementPosition, setElementPosition] = useState<{ left: number; top: number }>({ top: 0, left: 0 });
    const [design, setDesign] = useState<{ top: number; left: number }[]>();
    const [dev, setDev] = useState<string>('');

    const renderCursor = () => {
        return (
            <AnimatePresence>
                {isInside && (
                    <TI.Tile__Graphic
                        ref={refTileGraphic}
                        style={{ left: elementPosition.left, top: elementPosition.top, x: 0, y: 0, z: 0 }}
                        exit={{ scale: 0.72, opacity: 0, transition: { duration: 0.15 } }}
                        transition={{ ease: 'linear', duration: 0.2 }}
                    />
                )}
            </AnimatePresence>
        );
    };

    const drawDesign = useCallback(
        (top: number, left: number) => {
            setDesign(value => (value ? [...value, { top, left }] : [{ top, left }]));
        },
        [setDesign]
    );

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (refTile && refTileGraphic && refTile.current && refTileGraphic.current) {
            const top =
                e.clientY -
                refTile.current.getBoundingClientRect().top -
                refTileGraphic.current.getBoundingClientRect().height / 2;

            const left =
                e.clientX -
                refTile.current.getBoundingClientRect().left -
                refTileGraphic.current.getBoundingClientRect().width / 2;

            setElementPosition({ top, left });

            if (timeout !== null) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(async () => {
                cycleX();
                cycleBackground();
            }, 500);
            drawDesign(top, left);
        }
    };

    return (
        <TI.Tile
            ref={refTile}
            onMouseMove={handleMouseMove}
            onMouseEnter={() => {
                setDesign([]);
                setInside(true);
                setDev(`console.log(${poems[Math.floor(Math.random() * poems.length)]})`);
            }}
            onMouseLeave={() => {
                setInside(false);
                setDev(``);
            }}
            modifiers={type !== 'none' ? [type] : modifiers}
        >
            <TI.Tile__Header>
                <Text
                    type={'h4'}
                    modifiers={['weight600', 'uppercase', 'mono', 'small', 'spacingTop', 'spacingBottom']}
                    spacingTop={'xs'}
                    spacingBottom={'xs'}
                >
                    {title}
                </Text>
            </TI.Tile__Header>
            <TI.Tile__Footer>
                <Text
                    type={'h4'}
                    modifiers={
                        type === 'none'
                            ? ['headline', 'outline', 'uppercase', 'weight300', 'center', 'mono']
                            : ['advert', 'outline', 'uppercase', 'weight300', 'center', 'mono']
                    }
                >
                    {shortTitle}
                </Text>
            </TI.Tile__Footer>
            {type === 'research' && (
                <>
                    {renderCursor()}
                    <TI.Tile__Graphic
                        modifiers={['childIII']}
                        animate={
                            isInside
                                ? {
                                      top: elementPosition.top - elementPosition.top * 0.5,
                                      left: elementPosition.left - elementPosition.left * 0.32,
                                      x: 0,
                                      y: 0,
                                      z: 0
                                  }
                                : { x: '-50%', y: '-50%', top: '45%', left: '50%' }
                        }
                        transition={{ delay: 0, ease: 'easeInOut' }}
                    />
                </>
            )}
            {type === 'strategy' && (
                <>
                    {renderCursor()}
                    <TI.Tile__Graphic
                        ref={refTileGraphic}
                        modifiers={['childII']}
                        animate={
                            isInside
                                ? { scale: 0.72, borderRadius: xC }
                                : { x: '-50%', y: '-50%', top: '45%', left: '50%' }
                        }
                        transition={{ delay: 0.05, duration: 0.25, ease: 'easeInOut' }}
                    />
                </>
            )}
            {type === 'design' && (
                <>
                    {renderCursor()}
                    {design ? (
                        design.map((item: any, index: number) => {
                            return (
                                <TI.Tile__Graphic
                                    key={index}
                                    modifiers={['childIV']}
                                    style={{
                                        scale: 0.32,
                                        left: item.left,
                                        top: item.top,
                                        x: 0,
                                        y: 0,
                                        z: 0,
                                        zIndex: 1
                                    }}
                                    transition={{ delay: 0.1, duration: 0, ease: 'easeInOut' }}
                                />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    <TI.Tile__Graphic
                        ref={refTileGraphic}
                        modifiers={!isInside ? ['childIV'] : ['transparent', 'border']}
                        style={{ x: '-50%', y: '-50%', top: '45%', left: '50%' }}
                        transition={{ delay: 0.05, duration: 0.25, ease: 'easeInOut' }}
                    />
                </>
            )}
            {type === 'development' && (
                <>
                    {renderCursor()}
                    {dev !== '' && (
                        <Motion.div
                            animate={{ scale: [0.9, 1], opacity: [0, 1] }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'absolute',
                                zIndex: 3,
                                padding: 20,
                                width: '100%',
                                height: '100%',
                                opacity: 0,
                                y: -(elementPosition.top * 0.25)
                            }}
                            transition={{ delay: 0.5, duration: 0.15, ease: 'easeInOut' }}
                        >
                            <Text type={'span'} modifiers={['tertiary', 'weight800', 'colorWhite']}>
                                {dev}
                            </Text>
                        </Motion.div>
                    )}
                    <TI.Tile__Graphic
                        modifiers={['child']}
                        style={{ zIndex: 2, x: '-50%', y: '-50%', top: '45%', left: '50%' }}
                        transition={{ ease: 'easeInOut', duration: 0 }}
                    />
                </>
            )}
            {/* <TI.Tile__GraphicResearch
                animate={{ x: ['200%', '-200%', '200%'], y: ['-50%', '-50%', '-50%'] }}
                transition={{ repeat: Infinity, duration: 3 }}
            /> */}
        </TI.Tile>
    );
};
