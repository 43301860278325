import React from 'react';
import { Link } from 'gatsby';
import { motion as Motion } from 'framer-motion';

import { Grid, Grid__Item } from 'components/Grid';
import { Logo } from 'atoms/Logo';
import { Menu } from 'components/Menu';

import * as MH from './css';

export interface MainHeaderProps {}

export const MainHeader: React.FC<MainHeaderProps> = () => {
    return (
        <MH.MainHeader>
            <Grid modifiers={['alignCenter']}>
                <Grid__Item responsive={{ xs: { start: 1, span: 6 }, sm: { start: 1, span: 6 } }}>
                    <Link to={'/'} title={'homepage'}>
                        <Logo />
                    </Link>
                    {/* <Motion.div animate={{ opacity: [0, 1], x: [-50, 0], transition: { ease: 'easeInOut' } }}>
                    </Motion.div> */}
                </Grid__Item>
                {/* <Grid__Item modifiers={['justifyEnd']} responsive={{ xs: { span: 6 }, sm: { span: 6 } }}>
                    <Menu />
                </Grid__Item> */}
            </Grid>
        </MH.MainHeader>
    );
};
