import { PxToRem } from 'common/functions/PxToRem';

export enum GapsSizes {
    xxs = 1,
    xs = 3,
    s = 5,
    m = 10,
    l = 15,
    xl = 20,
    xxl = 35,
    xxxl = 60,
    xxxxl = 100,
    xxxxxl = 150,
    xxxxxxl = 200,
    xxxxxxxl = 300
}

export const Gaps = {
    xxs: PxToRem(GapsSizes.xxs),
    xs: PxToRem(GapsSizes.xs),
    s: PxToRem(GapsSizes.s),
    m: PxToRem(GapsSizes.m),
    l: PxToRem(GapsSizes.l),
    xl: PxToRem(GapsSizes.xl),
    xxl: PxToRem(GapsSizes.xxl),
    xxxl: PxToRem(GapsSizes.xxxl),
    xxxxl: PxToRem(GapsSizes.xxxxl),
    xxxxxl: PxToRem(GapsSizes.xxxxxl),
    xxxxxxl: PxToRem(GapsSizes.xxxxxxl),
    xxxxxxxl: PxToRem(GapsSizes.xxxxxxxl)
};
