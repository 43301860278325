import React from 'react';
import { Link } from 'gatsby';

import { Grid, Grid__Item } from 'components/Grid';
import { Text } from 'atoms/Text';
import { Link as ELink } from 'atoms/Link';
import * as FO from './css';

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
    return (
        <FO.Footer>
            <FO.Footer__Content>
                <Grid>
                    <Grid__Item responsive={{ md: { start: 6, span: 6 }, xl: { start: 7, span: 4 } }}>
                        <Text type={'h4'} modifiers={['subline']}>
                            <Text type={'span'} modifiers={['block', 'colorInverted']}>
                                Long time.
                            </Text>
                            <Text type={'span'} modifiers={['block', 'weight400', 'colorInverted']}>
                                No see?
                            </Text>
                        </Text>
                        <Text
                            type={'p'}
                            modifiers={[
                                'weight500',
                                'spacingTop',
                                'spacingBottom',
                                'lineHeightEditorial',
                                'colorInverted'
                            ]}
                            spacingTop={'xl'}
                            spacingBottom={'xxl'}
                        >
                            I’m always interested in cool collaborations and projects. If you like to have a chat or if
                            you are up for a beer. Drop me a line.
                        </Text>
                        <Text modifiers={['spacingBottom']} spacingBottom={'xxxl'}>
                            <ELink hRef={'mailto:hallo@timobusse.de'} title={'Get in touch'} modifiers={['inverted']}>
                                Get in touch
                            </ELink>
                        </Text>
                    </Grid__Item>
                </Grid>
                <Grid>
                    <Grid__Item responsive={{ sm: { start: 1, span: 6 } }}>
                        <Text type={'p'} modifiers={['xSmall', 'weight500', 'colorInverted']}>
                            <Text type={'span'} modifiers={['weight700', 'block', 'spacingBottom']} spacingBottom={'s'}>
                                everchanging \ Wiege für digitales Gut.
                            </Text>
                            Brunnenallee 102
                            <br />
                            32257 Bünde
                        </Text>
                    </Grid__Item>
                    <Grid__Item
                        modifiers={['justifyEnd', 'alignEnd']}
                        justifyEndXs={true}
                        responsive={{ sm: { span: 6 } }}
                    >
                        <Text type={'p'} modifiers={['xSmall', 'weight500', 'colorInverted']}>
                            <Link to="/legal" style={{ color: 'inherit' }}>
                                Legal
                            </Link>
                        </Text>
                        &nbsp;&nbsp;
                        <Text type={'p'} modifiers={['xSmall', 'weight500', 'colorInverted']}>
                            Handcrafted in Berlin with ∞ ♥
                        </Text>
                    </Grid__Item>
                </Grid>
            </FO.Footer__Content>
            <FO.Footer__BackgroundFont>
                <Text type={'h4'} modifiers={['mega', 'outlineHighlightII']}>
                    Hallo
                </Text>
            </FO.Footer__BackgroundFont>
        </FO.Footer>
    );
};
