import { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { down } from 'styled-breakpoints';

import { PxToRem } from 'common/functions/PxToRem';
import { ResTypography } from 'common/functions/ResTypography';
import { Gaps } from 'common/settings/Gaps';
import { TextContexts } from 'common/settings/Typography';

import { Colors } from 'common/settings/Colors';

export type T_Spacing = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';

const Spacers: { [key in T_Spacing]: string } = {
    xs: Gaps.s,
    s: Gaps.m,
    m: Gaps.l,
    l: Gaps.xl,
    xl: Gaps.xxl,
    xxl: Gaps.xxxl,
    xxxl: Gaps.xxxxl
};

export const TextModifiers = {
    mega: () => css`
        ${TextContexts.mega.general};
        ${ResTypography(TextContexts.mega.sizes)};
    `,
    advert: () => css`
        ${TextContexts.advert.general};
        ${ResTypography(TextContexts.advert.sizes)};
    `,
    headline: () => css`
        ${TextContexts.headline.general};
        ${ResTypography(TextContexts.headline.sizes)};
    `,
    subline: () => css`
        ${TextContexts.subline.general};
        ${ResTypography(TextContexts.subline.sizes)};
    `,
    tertiary: () => css`
        ${TextContexts.sublineSecondary.general};
        ${ResTypography(TextContexts.sublineSecondary.sizes)};
    `,
    paragraph: () => css`
        ${TextContexts.paragraph.general};
        ${ResTypography(TextContexts.paragraph.sizes)};
    `,
    body: () => css`
        ${TextContexts.body.general};
        ${ResTypography(TextContexts.body.sizes)};
    `,
    small: () => css`
        ${TextContexts.small.general};
        ${ResTypography(TextContexts.small.sizes)};
    `,
    xSmall: () => css`
        ${TextContexts.xSmall.general};
        ${ResTypography(TextContexts.xSmall.sizes)};
    `,
    marginCenter: () => css`
        margin: 0 auto;
    `,
    center: () => css`
        text-align: center;
    `,
    uppercase: () => css`
        text-transform: uppercase;
        letter-spacing: 0.08rem;
    `,
    inlineBlock: () => css`
        display: inline-block;
    `,
    legible: () => css`
        max-width: ${PxToRem(1000)};
    `,
    block: () => css`
        display: block;
    `,
    mono: () => css`
        font-family: 'Faktum Con', sans-serif;
        letter-spacing: 0.09rem;
    `,
    weight300: () => css`
        font-weight: 300;
    `,
    weight400: () => css`
        font-weight: 400;
    `,
    weight500: () => css`
        font-weight: 500;
    `,
    weight600: () => css`
        font-weight: 600;
    `,
    weight700: () => css`
        font-weight: 700;
    `,
    weight800: () => css`
        font-weight: 800;
    `,
    underline: () => css`
        text-decoration: underline;
    `,
    lineHeightHeadline: () => css`
        line-height: 1.1;
    `,
    lineHeightIncreased: () => css`
        line-height: 1.55;
    `,
    lineHeightEditorial: () => css`
        line-height: 1.75;
    `,
    isFront: () => css`
        position: relative;
        z-index: 4;
    `,
    linkButton: () => css`
        padding: ${Gaps.xl} ${Gaps.xxl};
        border-radius: ${Gaps.xxxl};
        background-color: ${props => props.theme.colors.foreground.I};
        color: ${props => props.theme.colors.background.I} !important;
        transition: background-color 0.35s ease;

        &:hover {
            background-color: ${props => lighten(0.2, props.theme.colors.foreground.I)};
        }
    `,
    interactive: () => css`
        position: relative;
        transition: padding 0.35s ease;
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        &:after {
            content: '›';
            position: relative;
            font-size: 32px;
            font-weight: 300;
            line-height: 0;
            margin-right: ${Gaps.m};
            color: ${props => props.theme.colors.highlight.II};
            transition: left 0.35s, 0.25s ease, opacity 0.35s, 0.25s ease;
        }
        &:hover {
            color: ${props => darken(0.2, props.theme.colors.highlight.I)};
            padding-left: ${Gaps.l};
            &:after {
                transition: left 0.35s, 0.25s ease, opacity 0.35s, 0.25s ease;
                left: ${Gaps.xs};
                opacity: 1;
            }
        }
    `,
    colorWhite: () => css`
        color: ${Colors.white} !important;
    `,
    colorInverted: () => css`
        color: ${props => props.theme.colors.background.I};
    `,
    colorDark: () => css`
        color: ${props => props.theme.colors.foreground.I};
    `,
    colorGreyDark: () => css`
        color: ${Colors.greyDarkII};
    `,
    colorGreyMedium: () => css`
        color: ${props => props.theme.colors.foreground.III};
    `,
    colorGreyLight: () => css`
        color: ${props => props.theme.colors.foreground.IV};
    `,
    colorGreyLightWhite: () => css`
        color: ${props => lighten(0.07, props.theme.colors.foreground.IV)};
    `,
    colorGreen: () => css`
        color: ${props => props.theme.colors.highlight.I};
    `,
    colorHighlightI: () => css`
        color: ${props => props.theme.colors.highlight.I};
    `,
    colorHighlightII: () => css`
        color: ${props => props.theme.colors.highlight.II};
    `,
    colorHighlightIII: () => css`
        color: ${props => props.theme.colors.highlight.III};
    `,
    colorHighlightIV: () => css`
        color: ${props => props.theme.colors.highlight.IV};
    `,
    outline: () => css`
        color: ${props => lighten(0, props.theme.colors.background.I)};
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: ${props => lighten(0, props.theme.colors.foreground.II)};

        ${down('sm')} {
            -webkit-text-stroke-width: 1px;
        }
    `,
    outlineInverted: () => css`
        color: transparent;
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: ${props => lighten(0.8, props.theme.colors.background.II)};

        ${down('sm')} {
            -webkit-text-stroke-width: 1px;
        }
    `,
    outlineHighlightI: () => css`
        color: ${props => lighten(0.8, props.theme.colors.background.I)};
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: ${props => props.theme.colors.highlight.I};

        ${down('sm')} {
            -webkit-text-stroke-width: 1px;
        }
    `,
    outlineHighlightII: () => css`
        color: ${props => lighten(0.8, props.theme.colors.background.I)};
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: ${props => props.theme.colors.highlight.II};

        ${down('sm')} {
            -webkit-text-stroke-width: 1px;
        }
    `,
    outlineHighlightIII: () => css`
        color: ${props => lighten(0.8, props.theme.colors.background.I)};
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: ${props => props.theme.colors.highlight.III};

        ${down('sm')} {
            -webkit-text-stroke-width: 1px;
        }
    `,
    outlineHighlightIV: () => css`
        color: ${props => lighten(0.8, props.theme.colors.background.I)};
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: ${props => props.theme.colors.highlight.IV};

        ${down('sm')} {
            -webkit-text-stroke-width: 1px;
        }
    `,
    spacingTop: props => {
        return css`
            padding-top: ${props?.spacingTop ? Spacers[props.spacingTop] : Spacers.l};
        `;
    },
    spacingBottom: props => css`
        padding-bottom: ${props?.spacingBottom ? Spacers[props.spacingBottom] : Spacers.l};
    `,
    spacingRight: props => css`
        padding-right: ${props?.spacingRight ? Spacers[props.spacingRight] : Spacers.l};
    `,
    spacingLeft: props => css`
        padding-left: ${props?.spacingLeft ? Spacers[props.spacingLeft] : Spacers.l};
    `
};
