import { css, ThemeProps, FlattenInterpolation } from 'styled-components';
import { PxToRem } from 'common/functions/PxToRem';

export type T_SizesType = { [key: string]: string };

export type T_TypoSettingsType = {
    [key: string]: {
        general?: FlattenInterpolation<ThemeProps<any>>;
        sizes: T_SizesType;
    };
};

export const TextContexts: T_TypoSettingsType = {
    mega: {
        general: css`
            font-family: 'Faktum Wide';
            line-height: 1.1;
            font-weight: 600;
        `,
        sizes: {
            xs: PxToRem(100),
            sm: PxToRem(120),
            md: PxToRem(160),
            lg: PxToRem(240)
        }
    },
    advert: {
        general: css`
            font-family: 'Faktum Wide';
            line-height: 1.1;
            font-weight: 600;
        `,
        sizes: {
            xs: PxToRem(48),
            sm: PxToRem(56),
            md: PxToRem(56),
            lg: PxToRem(88),
            xl: PxToRem(112),
            xxl: PxToRem(136),
            xxxl: PxToRem(160)
        }
    },
    headline: {
        general: css`
            font-family: 'Faktum Wide';
            line-height: 1.45;
            font-weight: 600;
        `,
        sizes: {
            xs: PxToRem(32),
            sm: PxToRem(40),
            md: PxToRem(48),
            lg: PxToRem(64),
            xl: PxToRem(80),
            xxl: PxToRem(96)
        }
    },
    subline: {
        general: css`
            line-height: 1.15;
            font-weight: 600;
        `,
        sizes: {
            xs: PxToRem(30),
            sm: PxToRem(34),
            md: PxToRem(48),
            lg: PxToRem(56),
            xl: PxToRem(64)
        }
    },
    sublineSecondary: {
        general: css`
            line-height: 1.25;
            font-weight: 600;
        `,
        sizes: {
            xs: PxToRem(22),
            sm: PxToRem(24),
            md: PxToRem(26),
            lg: PxToRem(32),
            xl: PxToRem(32)
        }
    },
    paragraph: {
        general: css`
            font-weight: 500;
        `,
        sizes: {
            xs: PxToRem(17),
            sm: PxToRem(18),
            md: PxToRem(19),
            lg: PxToRem(20),
            xl: PxToRem(22),
            xxxl: PxToRem(26)
        }
    },
    body: {
        general: css`
            line-height: 1.45;
            font-weight: 400;
        `,
        sizes: {
            xs: PxToRem(15),
            sm: PxToRem(16),
            md: PxToRem(17),
            lg: PxToRem(18),
            xl: PxToRem(20)
        }
    },
    small: {
        general: css``,
        sizes: {
            xs: PxToRem(13),
            sm: PxToRem(13),
            md: PxToRem(13),
            lg: PxToRem(15),
            xl: PxToRem(17)
        }
    },
    xSmall: {
        general: css``,
        sizes: {
            xs: PxToRem(11),
            sm: PxToRem(12),
            md: PxToRem(12),
            lg: PxToRem(13),
            xl: PxToRem(15)
        }
    }
};
