import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Gaps } from 'common/settings/Gaps';
import { PxToRem } from 'common/functions/PxToRem';

export const Cv__Bar = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    padding-bottom: ${Gaps.xxl};
    height: 100%;
    max-height: 65%;
    width: ${PxToRem(1)};
    background: ${props => props.theme.colors.foreground.II};
    transform: translate(-${Gaps.xxl}, -60%);
    transition: max-height 0.75s ease;

    ${down('md')} {
        display: none;
    }
`;
