import styled from 'styled-components';

interface I_BackgroundSection {
    backgroundImage: string;
}

export const BackgroundSection = styled.div<I_BackgroundSection>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-image: ${props => `url(${props.backgroundImage})`};
    background-size: cover;
    background-position: center;
`;
