import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';

type T_Logo = keyof typeof Modifiers_Logo;

export interface I_Logo {
    modifiersLogo?: T_Logo[];
}

const Modifiers_Logo = {
    fluid: () => css`
        width: 100%;
    `,
    widthM: () => css`
        display: block;
        width: ${PxToRem(180)};

        ${down('md')} {
            width: ${PxToRem(120)};
        }
    `
};

export const Logo = styled.svg<I_Logo>`
    z-index: 20;
    width: ${PxToRem(120)};

    ${down('md')} {
        width: ${PxToRem(90)};
    }

    ${props => Modifiers(Modifiers_Logo, props.modifiersLogo)}
`;
