import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { Grid, Grid__Item } from 'components/Grid';
import { Text } from 'atoms/Text';
import * as IT from './css';

export interface ImageTextProps {}

function queryImage() {
    return useStaticQuery(graphql`
        query {
            timo: file(relativePath: { eq: "images/timo-speaking.png" }) {
                ...image
            }
        }
    `);
}

export const ImageText: React.FC<ImageTextProps> = () => {
    const { timo } = queryImage();

    return (
        <Grid modifiers={['spacingTop', 'noPadding']} spacingTop={[{ default: 'l' }]}>
            <Grid__Item>
                <IT.ImageText>
                    <Grid>
                        <Grid__Item responsive={{ md: { span: 8 } }}>
                            <IT.ImageText__Image>
                                <BackgroundImage fluid={timo.childImageSharp.fluid} />
                            </IT.ImageText__Image>
                        </Grid__Item>
                        <Grid__Item responsive={{ md: { span: 4 } }}>
                            <IT.ImageText__Text>
                                <Text type={'h3'} modifiers={['headline', 'colorHighlightIII']}>
                                    Talk, talk
                                </Text>
                                <Text
                                    type={'p'}
                                    modifiers={['paragraph', 'weight500', 'spacingTop', 'colorInverted']}
                                    spacingTop={'s'}
                                >
                                    This was at DesignIt in Munich.
                                </Text>
                            </IT.ImageText__Text>
                        </Grid__Item>
                    </Grid>
                </IT.ImageText>
            </Grid__Item>
        </Grid>
    );
};
