import styled, { css } from 'styled-components';
import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';

export type T_BandAid__Path = keyof typeof Modifiers_BandAid__Path;

export interface I_BandAid__Path {
    modifiers?: T_BandAid__Path[];
}

const Modifiers_BandAid__Path = {
    highlightII: (props: any) => css`
        fill: ${props.theme.colors.highlight.II};
    `,
    stroke: () => css`
        fill: none;
        stroke-width: ${PxToRem(3)};
        stroke: ${props => props.theme.colors.foreground.I};
    `
};

export const BandAid__Path = styled.path<I_BandAid__Path>`
    fill: ${props => props.theme.colors.highlight.I};

    ${props => {
        return Modifiers(Modifiers_BandAid__Path, props.modifiers);
    }}
`;
