import { T_Spacing } from 'common/functions/Spacer';
import { T_GridPoints } from 'components/Grid/css/css_Grid__Item';

interface I_BreakpointObject {
    [key: string]: T_Spacing;
}

export function BreakpointConverter(breakpoints: string[] | undefined): any[] | undefined {
    if (breakpoints !== undefined) {
        return breakpoints.map((point: any) => {
            const obj: I_BreakpointObject = {};
            const objKey: any = point.split('%')[0];
            const objValue: any = point.split('%')[1];

            obj[objKey] = objValue;

            return obj;
        });
    }
}

export function BreakpointColumnConverter(responsive: string[] | undefined): T_GridPoints | undefined {
    if (responsive !== undefined) {
        return responsive.reduce((accum: any, point: any) => {
            const obj = point
                .split('%')[1]
                .split(',')
                .reduce(
                    (item: any, current: any) =>
                        Object.assign(item, { [current.split('&')[0]]: Math.floor(current.split('&')[1]) }),
                    {}
                );
            return Object.assign(accum, { [point.split('%')[0]]: obj });
        }, {});
    }
}
