import { css, FlattenInterpolation } from 'styled-components';
import { up } from 'styled-breakpoints';

import { Gaps } from 'common/settings/Gaps';

export type T_Spacing = { [k: string]: 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl' };

const SPACER: any = {
    s: `${Gaps.xl}`,
    m: `${Gaps.xxl}`,
    l: `${Gaps.xxxl}`,
    xl: `${Gaps.xxxxl}`,
    xxl: `${Gaps.xxxxxl}`,
    xxxl: `${Gaps.xxxxxxl}`,
    xxxxl: `${Gaps.xxxxxxxl}`
};

type T_Spacer = (spacings: T_Spacing[], direction: 'padding-top' | 'padding-bottom') => FlattenInterpolation<any>;

export const Spacer: T_Spacer = function Spacer(spacings, direction) {
    return spacings.map((item: any) => {
        const key = Object.keys(item)[0];
        const padding = `${direction}`;
        const style = `${padding}: ${SPACER[item[key]]};`;
        return key !== 'default'
            ? css`
                  ${up(`${key}`)} {
                      ${style}
                  }
              `
            : css`
                  ${style}
              `;
    });
};
