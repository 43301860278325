import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';

export const Logos__Item = styled.li`
    flex-basis: calc(100% / 2);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: ${PxToRem(240)};

    ${up('md')} {
        flex-basis: calc(100% / 3);
    }

    ${up('lg')} {
        flex-basis: calc(100% / 6);
    }
`;
