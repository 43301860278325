import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../context/ThemeState';

import { Grid, Grid__Item } from 'components/Grid';
import { Image } from 'components/Image';
import { Text } from 'atoms/Text';
import * as ST from './css';

export interface StageProps {
    item: {
        brand: string;
        headline: string;
        tasks: string[];
        backgroundColor: string;
        alignment: string;
        image: string;
        darkTheme?: boolean;
    };
}

export const Stage: React.FC<StageProps> = ({ item }) => {
    const { changeTheme } = useContext(ThemeContext);
    const { brand, headline, darkTheme, backgroundColor, image, alignment, tasks } = item;

    useEffect(() => {
        changeTheme(darkTheme ? darkTheme : false);
    }, []);

    return (
        <Grid modifiers={['spacingTop']} spacingTop={[{ default: 'xl' }, { md: 'xl' }]}>
            <Grid__Item responsive={{ sm: { span: 12 }, lg: { start: 2, span: 10 } }}>
                <Grid modifiers={['noPadding']}>
                    <Grid__Item responsive={{ sm: { start: 2, span: 8 } }}>
                        <Text
                            modifiers={[
                                'body',
                                'lineHeightHeadline',
                                'uppercase',
                                'mono',
                                'weight500',
                                'spacingTop',
                                'spacingBottom'
                            ]}
                            spacingTop={'xxl'}
                            spacingBottom={'s'}
                        >
                            {brand}
                        </Text>
                        <Text type={'p'} modifiers={['small', 'weight500', 'uppercase', 'colorGreyMedium']}>
                            {tasks.map((skill: string, index: number) => (
                                <React.Fragment key={index}>
                                    <Text key={index} type={'span'} modifiers={['mono', 'weight400']}>
                                        {skill}
                                    </Text>
                                    {index < tasks.length - 1 && (
                                        <Text
                                            type={'span'}
                                            modifiers={['spacingLeft', 'spacingRight']}
                                            spacingLeft={'s'}
                                            spacingRight={'s'}
                                        >
                                            ×
                                        </Text>
                                    )}
                                </React.Fragment>
                            ))}
                        </Text>
                        <Text
                            modifiers={['subline', 'lineHeightHeadline', 'spacingTop', 'spacingBottom']}
                            spacingTop={'xxl'}
                            spacingBottom={'xxl'}
                        >
                            {headline}
                        </Text>
                    </Grid__Item>
                </Grid>
                <ST.Stage backgroundColor={backgroundColor} style={{ justifyContent: alignment }}>
                    {image !== 'images/sr-product-key-image-II.png' ? <Image src={image} /> : <ST.Stage__Caret />}
                </ST.Stage>
            </Grid__Item>
        </Grid>
    );
};
