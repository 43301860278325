import { darken } from 'polished';
import { HighlightSpanKind } from 'typescript';

interface I_Colors {
    [key: string]: string;
}

export const Colors: I_Colors = {
    greyDark: '#161616',
    greyDarkII: '#313131',
    greyDarkIII: '#585858',
    greyMedium: '#AEAEAE',
    greyLight: '#F2F2F2',
    greyLightII: '#F8F8F8',
    greyWhite: '#F0F0F0',
    white: '#FFFFFF',
    highLightGreen: '#5EAAA8',
    highLightRed: '#FF7170',
    highLightYellow: '#F5F183',
    highLightBlue: '#529EC8'
};

export const ThemeLight = {
    name: 'light',
    background: {
        I: Colors.white,
        II: Colors.greyLight,
        III: Colors.greyMedium
    },
    foreground: {
        I: Colors.greyDark,
        II: Colors.greyDarkII,
        III: darken(0.2, Colors.greyMedium),
        IV: darken(0.1, Colors.greyLight)
    },
    highlight: {
        I: Colors.highLightGreen, //darken(0.05, Colors.hightLightGreen)
        II: Colors.highLightRed,
        III: Colors.highLightYellow,
        IV: Colors.highLightBlue
    }
};

export const ThemeDark = {
    name: 'dark',
    background: {
        I: Colors.greyDark,
        II: Colors.greyDarkII,
        III: Colors.greyDarkIII
    },
    foreground: {
        I: Colors.white,
        II: Colors.greyWhite,
        III: Colors.greyMedium,
        IV: Colors.greyDarkIII
    },
    highlight: {
        I: Colors.highLightGreen,
        II: Colors.highLightRed,
        III: Colors.highLightYellow,
        IV: Colors.highLightBlue
    }
};
