import React, { useState, createContext, useEffect } from 'react';
import { MotionValue, useMotionValue } from 'framer-motion';
import ScrollLock from 'react-scrolllock';

import { Modal } from 'components/Modal';

interface I_OverlayProvider {
    children: React.ReactNode;
}

interface I_OverlayContext {
    modalStatus: boolean;
    setModalVisibility: () => void;
    setModalValues: (
        y: MotionValue<number>,
        boundingValues: { top: number; left: number; width: number; height: number },
        content: { title: string; headline: string; img: string }
    ) => void;
}

const initialState = {
    modalStatus: false,
    setModalVisibility: () => {},
    setModalValues: () => {}
};

export const OverlayContext = createContext<I_OverlayContext>(initialState);

export const OverlayProvider = ({ children }: I_OverlayProvider) => {
    const [isSelected, setSelected] = useState<boolean>(false);
    const [isShown, setShown] = useState<boolean>(false);
    const [value, setValue] = useState<{
        top: number;
        left: number;
        width: number;
        height: number;
        y: MotionValue<number>;
        content: { title: string; headline: string; img: string };
    }>({ top: 0, left: 0, width: 0, height: 0, y: useMotionValue(1), content: { title: '', headline: '', img: '' } });

    const closeModal = () => {
        setShown(() => false);
        setTimeout(() => setSelected(() => false), 750);
        history.back();
    };

    return (
        <>
            <OverlayContext.Provider
                value={{
                    modalStatus: !isSelected,
                    setModalVisibility: () => {
                        setSelected(() => !isSelected);
                        setShown(() => !isShown);
                        if (!isShown) {
                            history.pushState({}, 'Example', 'project');
                        }
                    },
                    setModalValues: (y, boundingValues, content) => {
                        if (boundingValues && content && y) {
                            setValue({
                                y: y,
                                top: boundingValues.top,
                                left: boundingValues.left,
                                width: boundingValues.width,
                                height: boundingValues.height,
                                content: content
                            });
                        }
                    }
                }}
            >
                {children}
                <ScrollLock isActive={isSelected} />
            </OverlayContext.Provider>
            {isSelected && <Modal isShown={isShown} {...value} closeModal={closeModal} />}
        </>
    );
};
