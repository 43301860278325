import styled from 'styled-components';
import { rgba } from 'polished';

import { Colors } from 'common/settings/Colors';

export const Stage__Cover = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, ${rgba(Colors.greyDark, 0)}, black);
`;
