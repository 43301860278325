import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

type T_ModifierFullImage = keyof typeof ModifiersFullImage;

interface I_FullImage {
    backgroundColor: string;
    modifiers?: T_ModifierFullImage[];
}

const ModifiersFullImage = {
    noPadding: () => css`
        padding: 0;

        ${up('sm')} {
            padding: 0;
        }

        ${up('lg')} {
            padding: 0;
        }
    `,
    withBorder: () => css`
        border: ${PxToRem(1)} solid ${props => props.theme.colors.foreground.IV};
    `
};

export const FullImage = styled.div<I_FullImage>`
    padding: ${Gaps.m};
    border-radius: ${PxToRem(12)};
    background: ${props => props.backgroundColor};
    overflow: hidden;

    ${up('sm')} {
        padding: ${Gaps.xxl};
    }

    ${up('lg')} {
        padding: ${Gaps.xxxl};
    }

    & img.imgGif {
        width: 100%;
    }

    ${props => Modifiers(ModifiersFullImage, props.modifiers)};
`;
