import styled, { css, keyframes } from 'styled-components';
import { motion as Motion } from 'framer-motion';

import { PxToRem } from 'common/functions/PxToRem';

interface I_Modal {
    top: number;
    left: number;
    width: number;
    height: number;
    isShown: boolean;
}

const Modal_Animation = keyframes`
    0% {
        opacity: 0
    }
    25% {
        opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      opacity: 1;
    }
`;

const Modal_Animation_Reverse = (top: number, left: number, width: number, height: number) => keyframes`
    from {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 1;
    }
    to {
        opacity: 0;
        top: ${top}px;
        left: ${left}px;
        width: ${width}px;
        height: ${height}px;
    }
`;

export const Modal = styled(Motion.div)<I_Modal>`
    position: fixed;
    z-index: 20;
    top: ${props => PxToRem(props.top)};
    left: ${props => PxToRem(props.left)};
    width: ${props => PxToRem(props.width)};
    height: ${props => PxToRem(props.height)};

    background: ${props => props.theme.colors.background.I};
    transform-origin: center center;
    overflow-y: scroll;

    ${props =>
        css`
            animation: ${props.isShown
                    ? Modal_Animation
                    : Modal_Animation_Reverse(props.top, props.left, props.width, props.height)}
                0.75s 0.25s cubic-bezier(0.16, 1, 0.3, 1) both;
        `}
`;
