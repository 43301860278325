import React from 'react';

import { BreakpointConverter, BreakpointColumnConverter } from 'common/functions/BreakpointConverter';
import { Cv } from 'components/Cv';
import { FullImage } from 'components/FullImage';
import { Gallery } from 'components/Gallery';
import { Grid, Grid__Item } from 'components/Grid';
import { ImageCouple } from 'components/ImageCouple';
import { Logos } from 'components/Logos';
import { Stage } from 'components/Stage';
import { Tile } from 'components/Tile';
import { DefList, HeadlineText, TextFactory, TextTeaser } from 'components/Sections';
import { Video } from 'components/Video';
import { ImageText } from 'components/Sections/ImageText';
import { ProjectTeaser } from 'components/ProjectTeaser';

export interface PageBuilderProps {
    body: any[];
}

export const PageBuilder: React.FC<PageBuilderProps> = ({ body }) => {
    return (
        <>
            {body.map((item: any, index: number) => {
                switch (item.type) {
                    case 'headlineText':
                        return (
                            <Grid
                                key={index}
                                modifiers={item.gridWrapper.modifiers}
                                spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
                                spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
                            >
                                <Grid__Item>
                                    <HeadlineText headline={item.headline} text={item.text} />
                                </Grid__Item>
                            </Grid>
                        );
                    case 'video':
                        return <Video key={index} item={item} />;
                    case 'fullImage':
                        return <FullImage key={index} item={item} />;
                    case 'imageCouple':
                        return <ImageCouple key={index} item={item} />;
                    case 'projectTeaser':
                        return <ProjectTeaser key={index} item={item} />;
                    case 'textTeaser':
                        return <TextTeaser key={index} modifiers={item.modifiers} item={item} />;
                    case 'logoLine':
                        return (
                            <Grid
                                key={index}
                                modifiers={item.gridWrapper.modifiers}
                                spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
                                spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
                            >
                                <Grid__Item responsive={{ sm: { start: 0, span: 12 } }}>
                                    <Logos key={index} headline={item.headline} />
                                </Grid__Item>
                            </Grid>
                        );
                    case 'gallery':
                        return (
                            <Grid key={index}>
                                <Grid__Item responsive={{ xs: { start: 0, end: 13 } }}>
                                    <Gallery />
                                </Grid__Item>
                            </Grid>
                        );
                    case 'cv':
                        return <Cv item={item} />;
                    case 'stage':
                        return <Stage key={index} item={{ ...item }} />;
                    case 'tasks':
                        return (
                            <Grid
                                key={index}
                                modifiers={item.gridWrapper.modifiers}
                                spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
                            >
                                <Grid__Item responsive={{ sm: { start: 2, span: 10 } }}>
                                    <Grid modifiers={['noPadding']}>
                                        <Grid__Item responsive={{ sm: { span: 12 } }}>
                                            {item.headline.map((item: any, index: number) => (
                                                <React.Fragment key={index}>{TextFactory(item)}</React.Fragment>
                                            ))}
                                        </Grid__Item>
                                    </Grid>
                                    <Grid modifiers={['noPadding']}>
                                        {item.items.map(
                                            (
                                                item: {
                                                    responsive: string[];
                                                    modifiers: any | undefined;
                                                    content: { title: string; shortTitle: string; type: T_TileType };
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <Grid__Item
                                                        key={index}
                                                        responsive={BreakpointColumnConverter(item.responsive)}
                                                    >
                                                        <Tile {...item.content} modifiers={item.modifiers} />
                                                    </Grid__Item>
                                                );
                                            }
                                        )}
                                    </Grid>
                                </Grid__Item>
                            </Grid>
                        );
                    case 'defList':
                        return (
                            <Grid
                                key={index}
                                modifiers={item.gridWrapper.modifiers}
                                spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
                            >
                                <Grid__Item responsive={BreakpointColumnConverter(item.gridWrapperItems[0].responsive)}>
                                    <DefList points={item.points} />
                                </Grid__Item>
                            </Grid>
                        );
                    case 'imageText':
                        return <ImageText />;
                    default:
                        return <></>;
                }
            })}
        </>
    );
};
