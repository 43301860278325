import React from 'react';

import { I_Grid } from './css/css_Grid';
import { I_Grid__Item } from './css/css_Grid__Item';
import * as GR from './css';

export interface GridProps extends I_Grid {
    children: React.ReactNode;
}

export interface Grid__ItemProps extends I_Grid__Item {
    children: React.ReactNode;
    onClick?: () => void;
}

export const Grid: React.FC<GridProps> = ({ children, modifiers, spacingTop, spacingBottom }) => {
    return (
        <GR.Grid modifiers={modifiers} spacingTop={spacingTop} spacingBottom={spacingBottom}>
            {children}
        </GR.Grid>
    );
};

export const Grid__Item: React.FC<Grid__ItemProps> = ({ children, responsive, modifiers, justifyEndXs, onClick }) => {
    return (
        <GR.Grid__Item modifiers={modifiers} justifyEndXs={justifyEndXs} responsive={responsive} onClick={onClick}>
            {children}
        </GR.Grid__Item>
    );
};
