import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

interface I_Stage {
    backgroundColor: string;
}

export const Stage = styled.div<I_Stage>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: ${props => props.backgroundColor};
    border-radius: ${PxToRem(12)};
    overflow: hidden;

    ${up('sm')} {
        margin-top: ${Gaps.xxl};
    }

    ${up('md')} {
        margin-top: ${Gaps.xxxl};
        height: 85vh;
        min-height: ${PxToRem(320)};
        max-height: ${PxToRem(480)};
    }

    ${up('lg')} {
        max-height: ${PxToRem(1000)};
    }
`;
