import React from 'react';

import * as LO from './css';
import { I_Logo } from './css/css_Logo';
import { I_Logo__Character } from './css/css_Logo__Character';

import * as AN from './animation';

export interface LogoProps {}
export interface SloganProps extends I_Logo, I_Logo__Character {}

export const Logo: React.FC<LogoProps> = () => {
    return (
        <LO.Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.05 106.96">
            <LO.Logo__Bar y="55.96" width="185.05" height="51" modifiers={['animationDelay']} />
            <LO.Logo__Bar width="94.69" height="51" />
            <LO.Logo__Character d="M24.28,85.19l4.08,1c-1.2,5.28-4.64,7.56-8.72,7.56-5.48,0-9.24-4-9.24-10s3.76-10,9.24-10c4.24,0,7.56,2.4,8.72,7.55l-4.08,1c-.64-3.75-1.92-4.88-4.64-4.88-3.64,0-5,1.76-5,6.32s1.4,6.32,5,6.32C22.36,90.07,23.64,88.91,24.28,85.19Z" />
            <LO.Logo__Character d="M49,81.35v12H45V81.67c0-2.76-1.28-4.2-3.64-4.2-3,0-4.76,2.36-4.76,6.84v9H32.48v-28h4.08V78.6c.84-3,2.64-4.84,6.12-4.84C46.84,73.76,49,76.4,49,81.35Z" />
            <LO.Logo__Character d="M71.52,74.16V93.35H67.44v-4a6.63,6.63,0,0,1-6.28,4.4c-5.08,0-8.2-4-8.2-10s3.08-10,8-10a6.78,6.78,0,0,1,6.44,4.47V74.16ZM67.6,83.75c0-4.52-1.52-6.28-5.24-6.28s-5.24,1.76-5.24,6.28S58.6,90,62.36,90,67.6,88.23,67.6,83.75Z" />
            <LO.Logo__Character d="M93.48,81.35v12H89.4V81.67c0-2.76-1.28-4.2-3.64-4.2-3,0-4.76,2.36-4.76,6.84v9H76.92V74.16H81V78.6c.84-3,2.64-4.84,6.12-4.84C91.28,73.76,93.48,76.4,93.48,81.35Z" />
            <LO.Logo__Character d="M115.16,74.16V91.71c0,5.4-3.4,8.84-9,8.84-4.48,0-7.6-1.76-8.64-5.32l4-1.68c.76,2.84,1.88,3.6,4.8,3.6,3.44,0,4.72-1.72,4.72-5V88.27a6.39,6.39,0,0,1-6,4.12c-4.52,0-7.56-3.8-7.56-9.32s3-9.31,7.48-9.31A6.48,6.48,0,0,1,111,78V74.16Zm-3.92,9c0-3.92-1.52-5.72-4.8-5.72s-4.84,1.72-4.84,5.72,1.48,5.64,4.76,5.64S111.24,87,111.24,83.11Z" />
            <LO.Logo__Character d="M119.8,67.44c0-1.72,1-2.64,2.8-2.64a2.7,2.7,0,1,1,0,5.4A2.56,2.56,0,0,1,119.8,67.44Zm.76,6.72h4.08V93.35h-4.08Z" />
            <LO.Logo__Character d="M146.6,81.35v12h-4.08V81.67c0-2.76-1.28-4.2-3.64-4.2-3,0-4.76,2.36-4.76,6.84v9H130V74.16h4.08V78.6c.84-3,2.64-4.84,6.12-4.84C144.4,73.76,146.6,76.4,146.6,81.35Z" />
            <LO.Logo__Character d="M168.28,74.16V91.71c0,5.4-3.4,8.84-9,8.84-4.48,0-7.6-1.76-8.64-5.32l4-1.68c.76,2.84,1.88,3.6,4.8,3.6,3.44,0,4.72-1.72,4.72-5V88.27a6.39,6.39,0,0,1-6,4.12c-4.52,0-7.56-3.8-7.56-9.32s3-9.31,7.48-9.31A6.48,6.48,0,0,1,164.16,78V74.16Zm-3.92,9c0-3.92-1.52-5.72-4.8-5.72s-4.84,1.72-4.84,5.72,1.48,5.64,4.76,5.64S164.36,87,164.36,83.11Z" />
            <LO.Logo__Character d="M27.44,29.07H14.64c.24,3.84,1.64,5.28,4.72,5.28,2.28,0,3.32-.68,4-3l3.68,1.32c-1.24,3.48-4.12,5.08-7.8,5.08-5.32,0-8.88-3.92-8.88-10s3.68-10,8.72-10,8.36,3.72,8.36,9.6C27.48,28,27.48,28.31,27.44,29.07Zm-12.76-3h8.64c0-3.56-1.32-4.92-4.12-4.92S15,22.71,14.68,26.07Z" />
            <LO.Logo__Character d="M47.72,18.19l-6.88,19.2H35.76l-6.88-19.2h4.56l4.88,16,4.84-16Z" />
            <LO.Logo__Character d="M66.16,29.07H53.36c.24,3.84,1.64,5.28,4.72,5.28,2.28,0,3.32-.68,4-3l3.68,1.32c-1.24,3.48-4.12,5.08-7.8,5.08-5.32,0-8.88-3.92-8.88-10s3.68-10,8.72-10,8.36,3.72,8.36,9.6C66.2,28,66.2,28.31,66.16,29.07Zm-12.76-3H62c0-3.56-1.32-4.92-4.12-4.92S53.72,22.71,53.4,26.07Z" />
            <LO.Logo__Character d="M81.56,18.15v4a5.1,5.1,0,0,0-2.28-.48c-2.92,0-4.88,3.2-4.88,7.68v8.08H70.32V18.19H74.4v5.88c.48-3.2,1.76-6.24,5.12-6.24A5.08,5.08,0,0,1,81.56,18.15Z" />
        </LO.Logo>
    );
};

export const Slogan: React.FC<SloganProps> = ({ modifiers, modifiersLogo }) => {
    return (
        <LO.Logo modifiersLogo={modifiersLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284 43">
            <LO.Logo__Polyline points="284 0 284 43 0 43 0 0" />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M29.88,13.6l-4.05,17H23.27L20.1,18.23,17,30.65H14.38l-4.1-17h2.47l3,12.88L18.92,13.6h2.37l3.19,12.78,3-12.78Z"
            />
            <LO.Logo__Character modifiers={modifiers} d="M34.55,13.6v17H32.2v-17Z" />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M40.67,15.82v5h5.39V23H40.67v5.44h6.89v2.22H38.32v-17h9.24v2.22Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M61.17,21.77v8.88h-2V29.21A4.56,4.56,0,0,1,55.31,31,5.23,5.23,0,0,1,50,25.51V18.93a5.43,5.43,0,0,1,5.65-5.71,5.21,5.21,0,0,1,5.44,5.48v.11H58.71V18.7a3,3,0,0,0-3.1-3.26A3.21,3.21,0,0,0,52.33,19v6.33a3.19,3.19,0,0,0,3.32,3.52c2,0,3.33-1.22,3.33-3.5V24H55.54V21.77Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M66.85,15.82v5h5.4V23h-5.4v5.44h6.9v2.22H64.5v-17h9.25v2.22Z"
            />
            <LO.Logo__Character modifiers={modifiers} d="M84.8,15.82v5h5V23h-5v7.64H82.46v-17h9.07v2.22Z" />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M105,13.6V25.29c0,3.32-2.14,5.74-5.53,5.74s-5.52-2.42-5.52-5.74V13.6h2.34V25.3c0,2.07,1.2,3.51,3.18,3.51s3.18-1.44,3.18-3.51V13.6Zm-4.72,1.56H98.77V9.6h1.49Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M112.08,23H111v7.67h-2.34v-17h4.8c3.51,0,5.08,2.3,5.08,4.69a4.46,4.46,0,0,1-3.89,4.59l5.45,7.77h-2.77ZM111,20.79h2.46a2.4,2.4,0,0,0,2.69-2.5,2.37,2.37,0,0,0-2.69-2.47H111Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M132.79,13.6c3.6,0,5.72,2.16,5.72,5.43V25.2c0,3.27-2.12,5.45-5.72,5.45h-5.14v-17ZM130,28.43h2.8a3,3,0,0,0,3.38-3.23V19.05c0-2-1.22-3.23-3.38-3.23H130Z"
            />
            <LO.Logo__Character modifiers={modifiers} d="M144.23,13.6v17h-2.35v-17Z" />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M158.78,21.77v8.88h-2V29.21a4.53,4.53,0,0,1-3.9,1.82,5.23,5.23,0,0,1-5.35-5.52V18.93a5.43,5.43,0,0,1,5.65-5.71,5.22,5.22,0,0,1,5.44,5.48v.11h-2.35V18.7a3,3,0,0,0-3.09-3.26A3.21,3.21,0,0,0,149.94,19v6.33c0,2.09,1.2,3.52,3.31,3.52a3.14,3.14,0,0,0,3.34-3.5V24h-3.44V21.77Z"
            />
            <LO.Logo__Character modifiers={modifiers} d="M164.46,13.6v17h-2.35v-17Z" />
            <LO.Logo__Character modifiers={modifiers} d="M177.82,15.82h-4.29V30.65h-2.35V15.82h-4.32V13.6h11Z" />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M186.5,26.64h-5.57l-1.16,4h-2.51l5.28-17h2.35l5.26,17h-2.48Zm-.65-2.22-2.15-7.37-2.13,7.37Z"
            />
            <LO.Logo__Character modifiers={modifiers} d="M194.67,28.43H201v2.22h-8.67v-17h2.34Z" />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M205.69,15.82v5h5.39V23h-5.39v5.44h6.89v2.22h-9.24v-17h9.24v2.22Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M223.23,18.48a2.9,2.9,0,0,0-3-3,2.43,2.43,0,0,0-2.7,2.34c0,1.44,1.07,2.21,2.6,2.79l1.74.64c2.19.83,3.94,2.14,3.94,4.87,0,3.12-2.63,5-5.48,5a5.3,5.3,0,0,1-5.55-5.43v-.42h2.35v.4a3.08,3.08,0,0,0,3.2,3.23c1.76,0,3.14-1,3.14-2.63s-1.13-2.28-2.78-2.91L219,22.64c-2.13-.81-3.77-2.13-3.77-4.75s2.1-4.67,5-4.67a5,5,0,0,1,5.3,5.21v.44h-2.32Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M245.22,21.77v8.88h-1.95V29.21A4.55,4.55,0,0,1,239.36,31,5.23,5.23,0,0,1,234,25.51V18.93a5.43,5.43,0,0,1,5.66-5.71,5.22,5.22,0,0,1,5.44,5.48v.11h-2.35V18.7a3,3,0,0,0-3.09-3.26A3.21,3.21,0,0,0,236.38,19v6.33a3.19,3.19,0,0,0,3.32,3.52,3.15,3.15,0,0,0,3.34-3.5V24h-3.45V21.77Z"
            />
            <LO.Logo__Character
                modifiers={modifiers}
                d="M259.49,13.6V25.29c0,3.32-2.14,5.74-5.53,5.74s-5.53-2.42-5.53-5.74V13.6h2.35V25.3c0,2.07,1.19,3.51,3.18,3.51s3.18-1.44,3.18-3.51V13.6Z"
            />
            <LO.Logo__Character modifiers={modifiers} d="M272.72,15.82h-4.3V30.65h-2.34V15.82h-4.33V13.6h11Z" />
        </LO.Logo>
    );
};
