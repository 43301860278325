import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { motion as Motion } from 'framer-motion';
import _ from 'lodash';

import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

interface I_Gallery {
    itemQty: number;
}

interface I_GalleryConstraints {
    itemQty: number;
}

export const PANE_WIDTHS: { [key: string]: number } = {
    xs: 300,
    sm: 480,
    md: 560,
    lg: 640
};

export const Gallery = styled(Motion.div)<I_Gallery>`
    position: absolute;
    display: flex;
    overflow-x: visible;
    width: ${props => PxToRem(PANE_WIDTHS['xs'] * props.itemQty)};
    height: inherit;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    ${props => {
        const length = props.itemQty;
        return _.map(Object.keys(PANE_WIDTHS), key =>
            key !== 'xs'
                ? css`
                      ${up(key)} {
                          width: ${PxToRem(PANE_WIDTHS[key] * length)};
                      }
                  `
                : ''
        );
    }}
`;

export const GalleryConstraints = styled.div<I_GalleryConstraints>`
    position: absolute;
    left: 50%;
    margin: 0 auto;
    width: ${props => PxToRem(PANE_WIDTHS['xs'] * props.itemQty * 1.9)};
    height: 100%;
    transform: translateX(-50%);
    perspective: 2000;

    ${props => {
        const length = props.itemQty;
        return _.map(Object.keys(PANE_WIDTHS), key =>
            key !== 'xs'
                ? css`
                      ${up(key)} {
                          width: ${PxToRem(PANE_WIDTHS[key] * length * 1.85)};
                      }
                  `
                : ''
        );
    }}
`;

export const GalleryWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    overflow-x: hidden;

    margin-left: -${Gaps.xl};
    height: ${PxToRem(560)};
    width: 100vw;

    ${up('lg')} {
        height: ${PxToRem(600)};
    }

    ${up('xl')} {
        margin-left: -${Gaps.xxl};
        height: ${PxToRem(600)};
    }

    ${up('xxl')} {
        height: ${PxToRem(720)};
    }
`;
