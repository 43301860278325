import styled from 'styled-components';

import { down, up } from 'styled-breakpoints';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';

export const Footer = styled.footer`
    display: flex;
    align-items: flex-end;
    padding: ${Gaps.xxxxxl} 0 ${Gaps.xxl};
    min-height: ${PxToRem(500)};
    background-color: ${props => props.theme.colors.foreground.I};
    transform: translate3d(0, 0, 0);

    ${down('md')} {
        padding-top: ${Gaps.xxxl};
    }

    ${up('md')} {
        padding-top: ${Gaps.xxxxl};
    }
`;
