import styled from 'styled-components';

import { PxToRem } from 'common/functions/PxToRem';

interface I_Stage {
    backgroundImage: string;
}

export const Stage = styled.div<I_Stage>`
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100vh;
    min-height: ${PxToRem(650)};
    background-image: ${props => `url(${props.backgroundImage})`};
    background-size: cover;
    background-position: center bottom;
`;
