import React, { useRef, useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Pane } from 'components/Pane';
import { Text } from 'atoms/Text';
import * as GA from './css';

const PROJECTS = [
    {
        textModifiers: ['colorInverted'],
        crossIsDark: false,
        title: 'Smart Reporting',
        headline: 'How to brand a digital health start-up',
        external: false,
        slug: '/projects/smart-reporting-branding',
        tasks: ['branding', 'design', 'strategy'],
        keyImg: '/images/sr-circle-lines.png',
        img: '/images/sr-brain.png',
        color: 'rgb(235, 51, 72)'
    },
    {
        textModifiers: ['colorInverted'],
        crossIsDark: false,
        title: 'Smart Reporting',
        headline: 'A medical product for structured and systematic diagnosis',
        external: false,
        slug: '/projects/smart-reporting-product',
        tasks: ['research', 'design-system', 'ui·ux', 'prototyping'],
        keyImg: '/images/sr-background-product.png',
        img: '/images/sr-product-design.png'
    },
    {
        textModifiers: ['colorInverted'],
        crossIsDark: true,
        title: 'BauWatch',
        headline: 'A holistic and streamlined offer creation process',
        external: false,
        slug: '/projects/bauwatch-offer',
        tasks: ['research', 'ui·ux', 'frontend'],
        img: '/images/key-img-bauwatch.png',
        color: '#006eff'
    },
    {
        textModifiers: ['colorInverted'],
        crossIsDark: false,
        title: 'Moerkerk',
        headline: 'A digital tree delivery',
        external: false,
        slug: '/projects/moerkerk-delivery',
        tasks: ['research', 'ui·ux', 'frontend'],
        img: '/images/key-img-moe.png',
        color: '#385958'
    },
    {
        textModifiers: ['colorInverted'],
        crossIsDark: false,
        title: 'Porsche ',
        headline: 'Merging between the real and the virtual',
        external: false,
        slug: '/projects/porsche-vivid',
        tasks: ['concept', 'ui·ux'],
        color: '#101010',
        keyImg: '/images/por-bg.png',
        img: '/images/por-vivid.png'
    },
    {
        textModifiers: ['colorInverted'],
        crossIsDark: true,
        title: 'Daimler Fleetboard',
        headline: 'The departure check for the digitized world',
        external: true,
        slug: 'https://media.daimler.com/marsMediaSite/de/instance/picture/Fleetboard-Vehicle-Lens.xhtml?oid=17144011',
        tasks: ['research', 'ui·ux', 'frontend'],
        color: '#e64522',
        img: '/images/key-img-fleetboard-v-lens.png'
    },
    {
        textModifiers: ['colorDark'],
        crossIsDark: true,
        title: 'Weekend4Two ',
        headline: 'The boutique travel portal from Switzerland',
        external: true,
        slug: 'https://www.weekend4two.ch/',
        tasks: ['research', 'ui·ux'],
        color: 'whitesmoke',
        img: '/images/key-img-w42.png'
    },
    {
        textModifiers: ['colorInverted'],
        crossIsDark: false,
        title: 'BrainCode ',
        headline: 'The story creation boutique agency',
        external: true,
        slug: 'https://www.braincode360.com/',
        tasks: ['concept', 'ui·ux', 'development'],
        color: 'whitesmoke',
        keyImg: '/images/bc-pattern.png',
        img: '/images/bc-ipads-flying.png'
    }
];

export interface GalleryProps {}

function queryImages() {
    return useStaticQuery(graphql`
        query {
            image1: file(relativePath: { eq: "images/sr-brain.png" }) {
                ...image
            }
            bgImage1: file(relativePath: { eq: "images/sr-circle-lines.png" }) {
                ...image
            }
            image2: file(relativePath: { eq: "images/sr-product-design.png" }) {
                ...image
            }
            bgImage2: file(relativePath: { eq: "images/sr-background-product.png" }) {
                ...image
            }
            image3: file(relativePath: { eq: "images/key-img-bauwatch.png" }) {
                ...image
            }
            image4: file(relativePath: { eq: "images/key-img-moe.png" }) {
                ...image
            }
            image5: file(relativePath: { eq: "images/por-vivid.png" }) {
                ...image
            }
            bgImage5: file(relativePath: { eq: "images/por-bg.png" }) {
                ...image
            }
            image6: file(relativePath: { eq: "images/key-img-fleetboard-v-lens.png" }) {
                ...image
            }
            image7: file(relativePath: { eq: "images/key-img-w42.png" }) {
                ...image
            }
            image8: file(relativePath: { eq: "images/bc-ipads-flying.png" }) {
                ...image
            }
            bgImage8: file(relativePath: { eq: "images/bc-pattern.png" }) {
                ...image
            }
        }
    `);
}

export const Gallery: React.FC<GalleryProps> = () => {
    const refGalleryConstrainst = useRef<HTMLDivElement>(null);
    const refGallery = useRef<HTMLDivElement>(null);
    const refGalleryItem = useRef<HTMLDivElement>(null);
    const [xTrans, setXTrans] = useState<number>(0);

    const data = queryImages();

    function initGallery() {
        if (window.innerWidth > 540 && refGalleryItem.current) {
            const width =
                Math.ceil(((refGalleryItem.current.getBoundingClientRect().width - 40) * (PROJECTS.length - 1)) / 100) *
                100;
            setXTrans(() => width);
        } else {
            setXTrans(() => 2160);
        }
    }

    useEffect(() => {
        if (typeof window !== undefined) {
            const handleResize = () => {
                initGallery();
            };

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <>
            <GA.GalleryWrapper>
                <Text type={'h4'} modifiers={['mega', 'weight400', 'outlineHighlightI']}>
                    {'Projects'}
                    {'Projects'}
                    {'Projects'}
                </Text>

                <GA.GalleryConstraints ref={refGalleryConstrainst} itemQty={PROJECTS.length}>
                    <GA.Gallery
                        ref={refGallery}
                        drag={'x'}
                        dragConstraints={refGalleryConstrainst}
                        itemQty={PROJECTS.length}
                        style={{ x: xTrans }}
                    >
                        {PROJECTS.map((project: any, index: number) => (
                            <GA.Gallery__Item key={index} ref={refGalleryItem}>
                                <Pane
                                    idx={index}
                                    title={project.title}
                                    headline={project.headline}
                                    img={
                                        data[`bgImage${index + 1}`]
                                            ? data[`bgImage${index + 1}`].childImageSharp.fluid
                                            : undefined
                                    }
                                    keyImg={data[`image${index + 1}`].childImageSharp.fluid}
                                    color={project.color}
                                    crossIsDark={project.crossIsDark}
                                    tasks={project.tasks}
                                    slug={project.slug}
                                    external={project.external}
                                    textModifiers={project.textModifiers}
                                />
                            </GA.Gallery__Item>
                        ))}
                    </GA.Gallery>
                </GA.GalleryConstraints>
            </GA.GalleryWrapper>
        </>
    );
};
