import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { Gaps } from 'common/settings/Gaps';

export const ImageText__Text = styled.div`
    display: flex;
    padding: ${Gaps.xxl} -${Gaps.xl} ${Gaps.xxl} 0;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    ${up('xl')} {
        margin-right: -${Gaps.xxl};
    }
`;
