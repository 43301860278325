import styled, { css, keyframes } from 'styled-components';

import { Modifiers } from 'common/functions/Modifiers';

type T_Logo__Character = keyof typeof Modifiers_Logo__Character;

export interface I_Logo__Character {
    modifiers?: T_Logo__Character[];
}

const Modifiers_Logo__Character = {
    slogan: () => css`
        fill: ${props => props.theme.colors.foreground.I};
    `,
    sloganLight: () => css`
        fill: ${props => props.theme.colors.foreground.IV};
    `
};

const AnimationLogoCharacter = keyframes`
    from {
        ${css`
            opacity: 0;
        `}
    }
    to {
        ${css`
            opacity: 1;
        `}
    }
`;

export const Logo__Character = styled.path<I_Logo__Character>`
    fill: ${props => props.theme.colors.background.I};
    animation: ${AnimationLogoCharacter} 0.2s 1.25s ease both;
    ${props => Modifiers(Modifiers_Logo__Character, props.modifiers)}
`;
