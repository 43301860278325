import styled from 'styled-components';
import { PxToRem } from 'common/functions/PxToRem';

interface I_CircleText {
    crossIsDark: boolean;
}

export const Circle__Text = styled.text<I_CircleText>`
    display: flex;
    align-items: center;
    justify-content: center;
    fill: ${props => (props.crossIsDark ? props.theme.colors.foreground.I : props.theme.colors.background.I)};
    font-size: ${PxToRem(40)};
    font-weight: 300;
`;
