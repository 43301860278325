import React, { useState, useRef, useEffect } from 'react';
import { FluidObject } from 'gatsby-image';
import { Link, navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled, { css } from 'styled-components';

import { Gaps } from 'common/settings/Gaps';
import {
    Circle as ACircle,
    Circle__CircOutline as ACircle__CircOutline,
    Circle__Text as ACircle__Text
} from 'atoms/Circle/css';
import { Text } from 'atoms/Text';
import { T_TextModifiers } from 'atoms/Text/css/css_Text';
import * as PA from './css';

export interface PaneProps {
    idx: number;
    title: string;
    headline: string;
    keyImg: FluidObject;
    tasks: string[];
    external: boolean;
    slug: string;
    textModifiers: T_TextModifiers[];
    crossIsDark: boolean;
    img?: string;
    color?: string;
}

export const Pane: React.FC<PaneProps> = ({
    headline,
    title,
    tasks,
    img,
    keyImg,
    color,
    crossIsDark,
    textModifiers,
    slug,
    external
}) => {
    const refPaneInner = useRef<HTMLDivElement>(null);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [startLongPress, setStartLongPress] = useState(false);
    const ms = 1250;

    useEffect(() => {
        let timerId: NodeJS.Timeout | undefined = undefined;

        if (startLongPress && slug !== undefined) {
            timerId = setTimeout(() => {
                if (!external) {
                    navigate(slug);
                } else {
                    if (typeof window !== undefined) {
                        const win = window.open(slug, '_system', 'location=yes');
                        win && win.focus();
                    }
                }
            }, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            timerId !== undefined && clearTimeout(timerId);
        };
    }, [ms, startLongPress, setIsSelected]);

    const renderLink = (external: boolean, slug: string, title: string, crossIsDark: boolean) => {
        const circleStyles = css`
            position: absolute;
            z-index: 200;
            top: ${Gaps.xl};
            right: ${Gaps.xl};
        `;
        const GatsbyLink = styled(Link)`
            ${circleStyles}
        `;

        const InternalLink = styled.a`
            ${circleStyles}
        `;

        const svgCircle = (
            <ACircle viewBox="0 0 60 60" width="60" height="60">
                <ACircle__CircOutline
                    className={'withAnim'}
                    cx="30"
                    cy="30"
                    r="25"
                    modifiers={['withAnim']}
                    crossIsDark={crossIsDark}
                />
                <ACircle__CircOutline cx="30" cy="30" r="25" crossIsDark={crossIsDark} />
                <ACircle__Text x="18" y="40" crossIsDark={crossIsDark}>
                    +
                </ACircle__Text>
            </ACircle>
        );

        return !external ? (
            <GatsbyLink to={slug} title={title}>
                {svgCircle}
            </GatsbyLink>
        ) : (
            <InternalLink href={slug} target={'_blank'} title={title}>
                {svgCircle}
            </InternalLink>
        );
    };

    return (
        <PA.Pane>
            {renderLink(external, slug, title, crossIsDark)}
            <PA.Pane__Inner
                ref={refPaneInner}
                isSelected={isSelected}
                color={color}
                onMouseDown={() => setStartLongPress(true)}
                onMouseUp={() => setStartLongPress(false)}
                onMouseLeave={() => setStartLongPress(false)}
                onTouchStart={() => setStartLongPress(true)}
                onTouchEnd={() => setStartLongPress(false)}
            >
                {startLongPress && <PA.Pane__Progress />}
                {keyImg && <PA.Pane__Img fluid={keyImg} alt={`${title} image`} />}
                {img && (
                    <PA.Pane__BgWrapper>
                        <BackgroundImage fluid={img} style={{ height: 'inherit' }} />
                    </PA.Pane__BgWrapper>
                )}
                <PA.Pane__Content>
                    {startLongPress && <PA.Pane__Progress />}
                    <PA.Pane__Header>
                        <Text
                            type={'h2'}
                            modifiers={[...textModifiers, 'tertiary', 'weight600', 'lineHeightHeadline', 'spacingTop']}
                            spacingTop={'l'}
                        >
                            {title}
                        </Text>
                        <Text
                            type={'h3'}
                            modifiers={[...textModifiers, 'body', 'spacingTop', 'spacingBottom']}
                            spacingTop={'xs'}
                            spacingBottom={'m'}
                        >
                            {headline}
                        </Text>
                    </PA.Pane__Header>
                    <PA.Pane__Footer>
                        <Text
                            type={'p'}
                            modifiers={[...textModifiers, 'xSmall', 'mono', 'spacingBottom']}
                            spacingBottom={'xl'}
                        >
                            {tasks.map((task: string, index: number) => (
                                <React.Fragment key={index}>
                                    <Text key={index} type={'span'} modifiers={['weight600']}>
                                        {task}
                                    </Text>
                                    {index < tasks.length - 1 && (
                                        <Text
                                            type={'span'}
                                            modifiers={['weight500', 'spacingLeft', 'spacingRight']}
                                            spacingLeft={'s'}
                                            spacingRight={'s'}
                                        >
                                            ×
                                        </Text>
                                    )}
                                </React.Fragment>
                            ))}
                        </Text>
                    </PA.Pane__Footer>
                </PA.Pane__Content>
            </PA.Pane__Inner>
        </PA.Pane>
    );
};
