import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export interface ImageProps {
    src: string;
    title?: string;
}

function queryImages() {
    return useStaticQuery(graphql`
        query {
            images: allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
                edges {
                    node {
                        relativePath
                        extension
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 1400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);
}

export const Image: React.FC<ImageProps> = ({ src, title }) => {
    const data = queryImages();
    const match = useMemo(() => data.images.edges.find(({ node }) => src === node.relativePath), [data, src]);

    if (!match) return null;
    const { node } = match;

    return <Img fluid={node.childImageSharp.fluid} style={{ width: '100%', height: '100%' }} title={title} />;
};
