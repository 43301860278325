import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import { Modifiers } from 'common/functions/Modifiers';
import { Gaps } from 'common/settings/Gaps';
import { PxToRem } from 'common/functions/PxToRem';
import { Spacer, T_Spacing } from 'common/functions/Spacer';

type T_Grid = keyof typeof Modifiers_Grid;

export interface I_Grid {
    modifiers?: T_Grid[];
    spacingTop?: T_Spacing[];
    spacingBottom?: T_Spacing[];
}

const Modifiers_Grid = {
    alignCenter: () => css`
        align-items: center;
    `,
    fluid: () => css`
        max-width: initial;
        height: 100%;
        grid-template-columns: repeat(auto-fill, minmax(${PxToRem(48)}, 1fr));
    `,
    maxWidth: () => css`
        max-width: ${PxToRem(1600)};
        margin: 0 auto;
    `,
    noPadding: () => css`
        padding-left: 0;
        padding-right: 0;
        ${up('xl')} {
            padding-left: 0;
            padding-right: 0;
        }
    `,
    spacingTop: (props: I_Grid) => {
        const { spacingTop } = props;
        return css`
            ${Spacer(spacingTop || [{ sm: 'xl' }], 'padding-top')}
        `;
    },
    spacingBottom: (props: I_Grid) => {
        const { spacingBottom } = props;
        return css`
            ${Spacer(spacingBottom || [{ sm: 'xl' }], 'padding-bottom')}
        `;
    }
};

export const Grid = styled.div<I_Grid>`
    display: grid;
    grid-row-gap: ${Gaps.xl};
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: ${Gaps.xl};
    padding-left: ${Gaps.xl};
    padding-right: ${Gaps.xl};
    height: inherit;
    max-width: 100vw;

    ${up('xl')} {
        grid-row-gap: ${Gaps.xxl};
        grid-column-gap: ${Gaps.xxl};
        padding-left: ${Gaps.xxl};
        padding-right: ${Gaps.xxl};
    }

    ${props => Modifiers(Modifiers_Grid, props.modifiers)}
`;
