import styled, { css } from 'styled-components';

import { Modifiers } from 'common/functions/Modifiers';
import { PxToRem } from 'common/functions/PxToRem';
import { Gaps } from 'common/settings/Gaps';
import { Colors } from 'common/settings/Colors';

type T_MainHeader__Menu__Bar = keyof typeof Modifiers_MainHeader__Menu__Bar;

interface I_MainHeader__Menu__Bar {
    modifiers?: T_MainHeader__Menu__Bar[];
}

export const MainHeader__Menu = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: ${PxToRem(40)};
    height: ${PxToRem(40)};
    border: 0;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.background.II};
    outline: 0;
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.25s ease-in;

    &:hover {
        background-color: ${props => props.theme.colors.foreground.I};

        & span {
            background-color: ${props => props.theme.colors.background.I};

            &:nth-child(1) {
                transform: translateX(-50%);
            }

            &:nth-child(2) {
                transform: translateX(50%);
            }
        }
    }
`;

const Modifiers_MainHeader__Menu__Bar = {
    narrow: () => css`
        width: 50%;
    `
};

export const MainHeader__Menu__Bar = styled.span<I_MainHeader__Menu__Bar>`
    width: 60%;
    height: ${PxToRem(2)};
    background-color: ${props => props.theme.colors.foreground.II};
    border-radius: ${Gaps.s};
    transition: background-color 0.35s ease-in, transform 0.25s ease-in;

    & + & {
        margin-top: ${Gaps.s};
    }

    ${props => Modifiers(Modifiers_MainHeader__Menu__Bar, props.modifiers)}
`;
