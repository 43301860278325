import styled, { css } from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Modifiers } from 'common/functions/Modifiers';
import { BreakpointsSizes } from 'common/settings/Breakpoints';

export type T_GridPoints = { [key in BreakpointsSizes]?: { start?: number; end?: number; span?: number } };
type T_Grid__Item = keyof typeof Modifiers_Grid__Item;

export interface I_Grid__Item {
    responsive?: T_GridPoints;
    modifiers?: T_Grid__Item[];
    justifyEndXs?: boolean;
}

const Modifiers_Grid__Item = {
    justifyEnd: (props: I_Grid__Item) => {
        const { justifyEndXs } = props;
        return css`
            display: flex;
            justify-content: flex-end;

            ${down('xs')} {
                justify-content: ${justifyEndXs ? 'initial' : 'flex-end'};
            }
        `;
    },
    alignEnd: () => css`
        display: flex;
        align-items: flex-end;
    `
};

export const Grid__Item = styled.div<I_Grid__Item>`
    grid-row: auto;
    grid-column-start: 1;
    grid-column-end: 13;

    ${props => Modifiers(Modifiers_Grid__Item, props.modifiers)}
    ${props => gridBuilder(props.responsive)}
`;

function gridBuilder(gridPoints: T_GridPoints | undefined) {
    if (gridPoints) {
        const providedBreakpoints = Object.keys(gridPoints) as BreakpointsSizes[];
        return providedBreakpoints.map((breakPoint: BreakpointsSizes) => {
            if (gridPoints[breakPoint]?.span === undefined) {
                if (breakPoint === 'xs') {
                    return css`
                        ${down(breakPoint)} {
                            grid-column-start: ${gridPoints[breakPoint]?.start};
                            grid-column-end: ${gridPoints[breakPoint]?.end};
                        }
                    `;
                } else {
                    return css`
                        ${up(breakPoint)} {
                            grid-column-start: ${gridPoints[breakPoint]?.start};
                            grid-column-end: ${gridPoints[breakPoint]?.end};
                        }
                    `;
                }
            } else {
                if (breakPoint === 'xs') {
                    return css`
                        ${down(breakPoint)} {
                            grid-column: ${gridPoints[breakPoint]?.start ? gridPoints[breakPoint]?.start : 'auto'} /
                                span ${gridPoints[breakPoint]?.span};
                        }
                    `;
                } else {
                    return css`
                        ${up(breakPoint)} {
                            grid-column: ${gridPoints[breakPoint]?.start ? gridPoints[breakPoint]?.start : 'auto'} /
                                span ${gridPoints[breakPoint]?.span};
                        }
                    `;
                }
            }
        });
    }
}
