import styled from 'styled-components';
import { Gaps } from 'common/settings/Gaps';

export const Logos__Svg = styled.svg`
    padding: 0 0 ${Gaps.xl};
    width: 100%;
    max-width: 70%;
`;

export const Logos__SvgPath = styled.path`
    fill: ${props => props.theme.colors.foreground.I};
`;

export const Logos__SvgRectangle = styled.rect`
    fill: ${props => props.theme.colors.foreground.I};
`;

export const Logos__SvgPolygon = styled.polygon`
    fill: ${props => props.theme.colors.foreground.I};
`;
