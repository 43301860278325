import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'common/styles/GlobalStyles';
import { ThemeLight, ThemeDark } from 'common/settings/Colors';

import { theme } from 'common/settings/Theme';
import { ThemeContext } from '../../context/ThemeState';
import { OverlayProvider } from '../../context/Overlay';
import { MainHeader } from 'components/MainHeader';
import { Seo, SeoProps } from 'components/Seo';

export interface MainProps extends SeoProps {
    children: React.ReactNode;
}

export const Main: React.FC<MainProps> = ({ children, seoProps }) => {
    const { isDark } = useContext(ThemeContext);

    return (
        <>
            <Seo seoProps={seoProps} />
            <ThemeProvider theme={{ ...theme, colors: isDark ? ThemeDark : ThemeLight }}>
                <OverlayProvider>
                    <GlobalStyles />
                    <MainHeader />
                    {children}
                </OverlayProvider>
            </ThemeProvider>
        </>
    );
};
