import React, { useState } from 'react';
import { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { Grid__Item } from 'components/Grid';
import { Text } from 'atoms/Text';
import { TextFactory } from 'components/Sections';
import { I_TextBlock } from 'components/Sections/TextFactory';
import { TextSpacer } from 'atoms/TextSpacer';

import * as CV from './css';

export interface CvPointProps {
    isSecond: boolean;
    period: string;
    jobTitle: string;
    companyLocation: string;
    text: I_TextBlock[];
}

export const CvPoint: React.FC<CvPointProps> = ({ isSecond, period, jobTitle, companyLocation, text }) => {
    const [isShown, setIsShown] = useState(false);

    const gridItemWrapper = (isSecond: boolean, child: React.ReactNode) => {
        return !isSecond ? (
            <Grid__Item responsive={{ sm: { start: 2, span: 11 }, md: { start: 2, span: 5 } }}>{child}</Grid__Item>
        ) : (
            <Grid__Item responsive={{ sm: { start: 2, span: 11 }, md: { span: 5 } }}>
                <TextSpacer
                    css={css`
                        ${down('xxxl')} {
                            margin-top: 0 !important;
                        }
                    `}
                >
                    {child}
                </TextSpacer>
            </Grid__Item>
        );
    };
    return gridItemWrapper(
        isSecond,
        <CV.Cv__Point>
            <Text
                type={'p'}
                modifiers={['small', 'weight400', 'colorGreyMedium', 'mono', 'spacingBottom']}
                spacingBottom={'m'}
            >
                {period}
            </Text>
            <Text type={'h3'} modifiers={['tertiary', 'weight700']}>
                {jobTitle}
            </Text>
            <Text type={'h4'} modifiers={['weight400', 'weight700', 'spacingTop']} spacingTop={'m'}>
                <Text type={'span'} modifiers={['colorGreen', 'weight500']}>
                    @&nbsp;
                </Text>
                {companyLocation}
            </Text>
            <CV.Cv__Point__Body isShown={isShown}>
                <CV.Cv__Point__Text isShown={isShown}>
                    {text.map((item: I_TextBlock) => TextFactory(item))}
                    {isShown && (
                        <div style={{ cursor: 'pointer' }} onClick={() => setIsShown(!isShown)}>
                            <Text
                                type={'p'}
                                modifiers={[
                                    'small',
                                    'inlineBlock',
                                    'weight700',
                                    'uppercase',
                                    'spacingTop',
                                    'interactive'
                                ]}
                                spacingTop={'xl'}
                                spacingBottom={'xxl'}
                            >
                                Show less
                            </Text>
                        </div>
                    )}
                </CV.Cv__Point__Text>
                {!isShown && (
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsShown(!isShown)}>
                        <Text
                            type={'span'}
                            modifiers={['small', 'inlineBlock', 'weight500', 'uppercase', 'spacingTop', 'interactive']}
                            spacingTop={'xl'}
                        >
                            <Text type={'span'} modifiers={['underline']}>
                                Show more
                            </Text>
                        </Text>
                    </div>
                )}
            </CV.Cv__Point__Body>
        </CV.Cv__Point>
    );
};
