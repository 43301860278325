import { createGlobalStyle } from 'styled-components';
import { ResTypography } from 'common/functions/ResTypography';
import { TextContexts } from 'common/settings/Typography';

export const GlobalStyles = createGlobalStyle`
      html {
        box-sizing: border-box;
      }

      *,
      *::before,
      *::after {
          box-sizing: inherit;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          -webkit-tap-highlight-color: transparent;
      }

      html, body {
          margin: 0;
          padding: 0;
      }
      body {
        ${TextContexts.body.general}
        ${ResTypography(TextContexts.body.sizes)}
        font-family: 'Faktum', sans-serif;
        color: ${props => props.theme.colors.foreground.I};
        background-color: ${props => props.theme.colors.background.I};
        transition: background-color 0.5s ease-in-out;
      }
      h1,h2,h3,h4,p,span,small {
        margin: 0;
      }

      a {
        text-decoration: none;
      }
`;
