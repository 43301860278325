import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { Modifiers } from 'common/functions/Modifiers';

type T_TextTeaser = keyof typeof Modifiers_TextTeaser;

export interface I_TextTeaser {
    modifiers?: T_TextTeaser[];
}

const Modifiers_TextTeaser = {
    backgroundInverted: () => css`
        background-color: ${props => props.theme.colors.foreground.I};
    `,
    backgroundWhite: () => css`
        background-color: ${props => lighten(0.1, props.theme.colors.background.I)};
    `
};

export const TextTeaser = styled.div<I_TextTeaser>`
    ${props => Modifiers(Modifiers_TextTeaser, props.modifiers)}
`;
