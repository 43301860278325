import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Gaps } from 'common/settings/Gaps';
import { Modifiers } from 'common/functions/Modifiers';

type T_ModifierTextSpacer = keyof typeof ModifiersTextSpacer;

export interface I_TextSpacer {
    css?: any;
    modifiers?: T_ModifierTextSpacer[];
}

const ModifiersTextSpacer = {
    medium: () => css`
        padding-top: ${Gaps.l};

        ${up('sm')} {
            padding-top: ${Gaps.xl};
        }

        ${up('md')} {
            padding-top: ${Gaps.xxl};
        }

        ${up('lg')} {
            padding-top: ${Gaps.xxxl};
        }
    `
};

export const TextSpacer = styled.div<I_TextSpacer>`
    ${up('md')} {
        padding-top: ${Gaps.xxxxl};
    }

    ${up('lg')} {
        padding-top: ${Gaps.xxxxxl};
    }

    ${props => Modifiers(ModifiersTextSpacer, props.modifiers)}
`;
