import React from 'react';
import { Link } from 'gatsby';

import { Text } from 'atoms/Text';
import { BandAid } from 'atoms/BandAid';

import { T_TextModifiers, T_TextType } from 'atoms/Text/css/css_Text';
import { T_Spacing } from 'atoms/Text/css/css_TextModifiers';
import { T_BandAid__Path } from 'atoms/BandAid/css/css_BandAid__Path';

export interface I_TextBlock {
    atom: string;
    type: T_TextType;
    content: string;
    link: string;
    withMark: string;
    markModifiers: T_BandAid__Path[];
    modifiers?: T_TextModifiers[];
    spacingTop?: T_Spacing;
    spacingBottom?: T_Spacing;
    spacingRight?: T_Spacing;
    children?: I_TextBlock[];
}

export interface TextFactoryProps extends I_TextBlock {}

export const TextFactory: React.FC<TextFactoryProps> = ({
    atom,
    type,
    link,
    withMark,
    markModifiers,
    content,
    modifiers,
    spacingTop,
    spacingBottom,
    spacingRight,
    children
}) => {
    const renderText = (
        type: T_TextType,
        content: string,
        modifiers?: T_TextModifiers[],
        spacingTop?: T_Spacing,
        spacingBottom?: T_Spacing,
        spacingRight?: T_Spacing,
        children?: I_TextBlock[]
    ) => (
        <Text
            type={type}
            modifiers={modifiers}
            spacingTop={spacingTop}
            spacingBottom={spacingBottom}
            spacingRight={spacingRight}
        >
            {content && content}
            {children !== undefined && children?.length > 0 ? (
                <>
                    {children.map((child: I_TextBlock, index: number) => (
                        <React.Fragment key={index}>
                            {renderText(
                                child.type,
                                child.content,
                                child.modifiers,
                                child.spacingTop,
                                child.spacingBottom,
                                child.spacingRight,
                                child.children
                            )}
                        </React.Fragment>
                    ))}
                </>
            ) : null}
        </Text>
    );

    return atom === 'text' ? (
        <Text
            type={type}
            modifiers={modifiers}
            spacingTop={spacingTop}
            spacingBottom={spacingBottom}
            spacingRight={spacingRight}
        >
            {content}
            {withMark && <BandAid modifiers={markModifiers} />}
            {children !== undefined && children?.length > 0 ? (
                <>
                    {children.map((child: I_TextBlock, index: number) => (
                        <React.Fragment key={index}>
                            {renderText(
                                child.type,
                                child.content,
                                child.modifiers,
                                child.spacingTop,
                                child.spacingBottom,
                                child.spacingRight,
                                child.children
                            )}
                        </React.Fragment>
                    ))}
                </>
            ) : null}
        </Text>
    ) : (
        <Link to={link}>
            <Text
                type={type}
                modifiers={modifiers}
                spacingTop={spacingTop}
                spacingBottom={spacingBottom}
                spacingRight={spacingRight}
            >
                {content}
            </Text>
        </Link>
    );
};
