import styled from 'styled-components';

export const Tile__Footer = styled.footer`
    position: relative;
    z-index: 4;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
