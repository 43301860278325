import React from 'react';

import { BreakpointConverter } from 'common/functions/BreakpointConverter';
import { Grid, Grid__Item } from 'components/Grid';
import { Image } from 'components/Image';

import * as FI from './css';

export interface FullImageProps {
    item: any;
}

export const FullImage: React.FC<FullImageProps> = ({ item }) => {
    const { backgroundColor, image } = item;
    return (
        <Grid
            modifiers={item.gridWrapper.modifiers}
            spacingTop={BreakpointConverter(item.gridWrapper.spacingTop)}
            spacingBottom={BreakpointConverter(item.gridWrapper.spacingBottom)}
        >
            <Grid__Item responsive={{ sm: { span: 12 }, lg: { start: 2, span: 10 } }}>
                <FI.FullImage backgroundColor={backgroundColor}>
                    <Image src={image} />
                </FI.FullImage>
            </Grid__Item>
        </Grid>
    );
};
