import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Gaps } from 'common/settings/Gaps';

export const Pane__Footer = styled.footer`
    position: relative;
    z-index: 3;
    opacity: 0.88;

    padding-left: ${Gaps.xxl};
    padding-right: ${Gaps.xxl};

    ${down('sm')} {
        padding-left: ${Gaps.xl};
        padding-right: ${Gaps.xl};
    }
`;
