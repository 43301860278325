import styled from 'styled-components';
import { Gaps } from 'common/settings/Gaps';

export const Tile__ImgWrapper = styled.div`
    position: absolute;
    z-index: 2;
    margin: -${Gaps.m} -${Gaps.xl};
    width: 100%;
    height: 100%;
`;
