import styled, { keyframes } from 'styled-components';
import { motion as Motion } from 'framer-motion';
import { rgba } from 'polished';
import { PxToRem } from 'common/functions/PxToRem';

interface I_Pane__Inner {
    isSelected: boolean;
    color?: string;
}

const AnimationPaneInner = (color: string) => keyframes`
    0% {
      background-color: ${rgba(color, 0)};
    }
    100% {
      background-color: ${rgba(color, 1)};
    }
`;

export const Pane__Inner = styled(Motion.article)<I_Pane__Inner>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: inherit;
    background-color: ${props => (props.color ? props.color : props.theme.colors.foreground.I)};
    background-position: center center;
    background-size: cover;
    border-radius: ${PxToRem(24)};
    transform-origin: center center;
    transition: background-color 1.25s ease-in-out;
    overflow: hidden;

    &:active {
        &:after {
            animation: ${props => AnimationPaneInner(props.theme.colors.highlight.I)} 0.75s 0.5s ease-in-out both;
        }
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
    }
`;
